import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private listener = new BehaviorSubject<string>("");
  message = this.listener.asObservable();

  constructor(private spinner: NgxSpinnerService) { }

  show(message: string = ''): void {
    this.listener.next(message);
    this.spinner.show();
  }

  hide(): void {
    this.listener.next('');
    this.spinner.hide();
  }
}
