<div id="hijack-hotspots" class="black-page-background">
    <div class="page-header-block">
        <h1 class="page-header">Hijack Hotspots</h1>
    </div>
    <app-check-location></app-check-location>
    <!-- /header -->
    <app-map [show]="mapShow" (hideMapEvent)="updateMapShow($event)"></app-map>
    <div class="ui-content">
        <ul class="button-list hijack-hotspots-page-list">
            <li>
                <a [routerLink]="" (click)="showHijackHotspots()" class="ui-icon-hijack_hotspots hijack-map-button" id="btn-view-hijack_hotspots">Hijack hotspots<span class="ui-icon-arrow-right"></span></a>
            </li>

            <li class="last" id="monitor-list-item">
                <span class="flipswitch-label">Monitor hijack hotspots</span>
                <div class="onoffswitch">
                    <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="myonoffswitch" #myOnOffSwitch (change)="checkboxChanged($event.target.checked)">
                    <label class="onoffswitch-label" for="myonoffswitch">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                    </label>
                </div>
            </li>
        </ul>
        <p class="small-margin-paragraph indent-paragraph copyright-container">
            &copy; Copyright TopicWorx
        </p>
    </div>
    <app-footer></app-footer>
</div>