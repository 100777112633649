<div id="maps">
    <div class="page-header-block">
        <h1 class="page-header">Maps Menu</h1>
    </div>
    <app-check-location></app-check-location>
    <!-- /header -->
    <app-map [show]="mapShow" (hideMapEvent)="updateMapShow($event)"></app-map>
    <div class="ui-content">
        <ul class="button-list">
            <li>
                <a [routerLink]="" (click)="showAllAlerts()" class="ui-icon-view-live-monitoring-map external-map-button" id="btn-map-view-national-alerts">View all alerts</a>
            </li>
            <li>
                <a [routerLink]="" (click)="showPlaces($event)" class="ui-icon-view-police places-map-button" id="btn-view-police">View nearby police stations</a>
            </li>
            <li>
                <a [routerLink]="" (click)="showPlaces($event)" class="ui-icon-view-hospital places-map-button" id="btn-view-hospital">View nearby hospitals</a>
            </li>
            <li>
                <a [routerLink]="" (click)="showPlaces($event)" class="ui-icon-view-gas_station places-map-button" id="btn-view-gas_station">View nearby filling stations</a>
            </li>
            <li>
                <a [routerLink]="" (click)="showPlaces($event)" class="ui-icon-view-atms places-map-button" id="btn-view-atms">View nearby Absa ATMs</a>
            </li>
            <li>
                <a [routerLink]="" (click)="showPlaces($event)" class="ui-icon-view-branches places-map-button" id="btn-view-branches">View nearby Absa Branches</a>
            </li>
            <li>
                <a [routerLink]="" (click)="navigateExternal('smallSpikingHotspot')" class="ui-icon-view-live-monitoring-map external-map-button" id="btn-view-spiking-hotspot">Spiking Hotspot</a>
            </li>
            <li class="last">
                <a routerLink="/route-planner" class="ui-icon-view-route_planner places-map-button" id="btn-view-gas_station">Route Planner</a>
            </li>
        </ul>
        <p class="small-margin-paragraph indent-paragraph copyright-container">
            &copy; Copyright TopicWorx
        </p>
    </div>
    <app-footer></app-footer>
</div>