import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MapService {
    show: boolean = false;
    data: any;
    private showAlertsSource = new Subject<boolean>();
    showAlerts$ = this.showAlertsSource.asObservable();


    constructor() { }

    setShowAlerts(status) {
        this.showAlertsSource.next(status);
    }
}
