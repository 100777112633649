import { Component, OnInit } from '@angular/core';
import { LogoutService } from '../logout.service';
import { ApiService } from '../api.service';
import { InstallButtonService } from '../install-button.service';
import { menu } from '../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CheckLocationService } from '../check-location.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css']
})

export class MenuComponent implements OnInit {
    isIos:boolean;
    pageName: string = 'menu';
    showWelcomeVal = false;
    notInstalled = false;
    mapShow: string;
    isToolTipHiden:boolean = false;
    intervalLoop: any;

    constructor(
        private deviceService: DeviceDetectorService,
        private logoutService: LogoutService,
        private api: ApiService,
        public installBtn: InstallButtonService,
    ) {
        this.intervalLoop = setInterval(() => {
            this.checkAccountStatus(); 
        }, this.logoutService.getCheckAccountStatusTimer());
    }

    ngOnInit(): void {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        } else {
            if (!menu.visited) {
                this.showWelcome();
                menu.visited = true;
            }
        }
        this.deviceService.getDeviceInfo().os == "iOS" ? this.isIos = true : this.isIos = false;
    }

    ngOnDestroy() {
        clearInterval(this.intervalLoop);
    }

    checkAccountStatus() {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        }
    }

    isTokenValid() {
        const error_code_arr = [401, 403];
        this.api.fetchSettings().subscribe({
            next: (data) => {
                if (data.message == 'token invalid') {
                    this.logoutService.logout();
                }
            },
            error: (error) => {
                error_code_arr.includes(error.status) ? this.logoutService.logout() : console.log(error);
            }
        });
    }

    showWelcome() {
        this.showWelcomeVal = true;
    }

    welcomeFinished(state: string) {
        this.checkInstalled();
    }

    checkInstalled() {
        if (!window.matchMedia('(display-mode: standalone)').matches) {
            this.notInstalled = true;
        }
    }

    hideToolTip(){
        this.installBtn.setTooltip(true);
    }

    logout() {
        this.logoutService.logout();
    }

}
