<div id="home">
    <div class="loading-container">
        <div class="loading">
            <span class="loading-text">Loading...</span>
        </div>
    </div>

    <app-check-location></app-check-location>

    <div class="ui-content" id="home-content">

        <pdf-viewer class="pdf-viewer" [src]="pdfSrc" [render-text]="true" [zoom]="1" [original-size]="false"></pdf-viewer>
        
    </div>
    <app-footer></app-footer>
</div>