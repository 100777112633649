import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, from, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PushNotificationService } from 'src/app/push-notification.service';
import { LogoutService } from '../logout.service';
import { ApiService } from '../api.service';
import { SubSink } from 'subsink';
import { LoaderService } from 'src/app/loader.service';
@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
    cellularData: boolean;
    notificationVibrate: boolean = true;
    notificationSound: boolean;
    isNotificationOn: boolean;
    radiusFormControl = new FormControl('');
    cellularDataFormControl = new FormControl('');
    notificationVibrateFormControl = new FormControl('');
    notificationSoundFormControl = new FormControl('');
    notificationFormControl = new FormControl('');
    pageName: string = 'settings';
    incognito: Observable<boolean>;
    subscribedToNotifications: boolean;
    private sub = new SubSink();
    intervalLoop: any;

    constructor(
        private loader: LoaderService,
        private notification: PushNotificationService,
        private toastr: ToastrService,
        private api: ApiService,
        private logoutService: LogoutService
    ) {
        this.intervalLoop = setInterval(() => {
            this.checkAccountStatus(); 
        }, this.logoutService.getCheckAccountStatusTimer());
    }

    ngOnInit(): void {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        } else {
            this.getSettings();
        }
        this.incognito = this.notification
            .hasNotification();
        this.notification
            .isSubscribedPushNotification()
            .subscribe(isSubscribedToNotification => this.subscribedToNotifications = isSubscribedToNotification);
    }

    checkAccountStatus() {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        }
    }

    isTokenValid() {
        const error_code_arr = [401, 403];
        this.api.fetchSettings().subscribe({
            next: (data) => {
                if (data.message == 'token invalid') {
                    this.logoutService.logout();
                }
            },
            error: (error) => {
                error_code_arr.includes(error.status) ? this.logoutService.logout() : console.log(error);
            }
        });
    }

    getSettings() {
        let settingsSubscription = this.api.fetchSettings().subscribe((resultData) => {
            settingsSubscription.unsubscribe();
            if (resultData.success) {
                this.radiusFormControl.setValue(resultData.extras.radius);
                this.cellularDataFormControl.setValue(resultData.extras.cellularData);
                this.notificationVibrateFormControl.setValue(resultData.extras.notificationVibrate);
                this.notificationSoundFormControl.setValue(resultData.extras.notificationSound);
                this.cellularData = this.convertToBool(resultData.extras.cellularData);
                this.notificationVibrate = this.convertToBool(resultData.extras.notificationVibrate);
                this.notificationSound = this.convertToBool(resultData.extras.notificationSound);
            }
        });
    }

    checkboxToggle(event) {
        let eventId = event.target.id.replace('check-', '');
        this[eventId] = event.target.checked;
    }

    submitSettings() {
        this.loader.show();
        let submitData = {
            radius: this.radiusFormControl.value,
            cellularData: this.convertToBool(this.cellularDataFormControl.value),
            alertSound: this.convertToBool(this.notificationSoundFormControl.value),
            alertVibrate: this.convertToBool(this.notificationVibrateFormControl.value)
        };
        let settingsSubscription = this.api.updateSettings(submitData).subscribe(resultData => {
            this.loader.hide();
            if (resultData.success) {
                this.toastr.success('Your settings have been updated.', '', { toastClass: 'absa-toast' });
            } else {
                this.loader.hide();
                this.toastr.error('We are unable to update your settings at this time.', '', { toastClass: 'absa-toast' });
            }
            settingsSubscription.unsubscribe();
        });
    }

    convertToBool(value: any): boolean {
        if (value === 0 || value === '0' || value === 'false' || value === false) {
            return false;
        }
        if (value === 1 || value === '1' || value === 'true' || value === true) {
            return true;
        }
    }

    unsubscribeToNotifications(): void {
        this.loader.show();
        this.sub.sink = this.notification
            .unSubscriptPushNotificationChannel()
            .subscribe(_ => {
                this.subscribedToNotifications = !_;
                this.loader.hide();
            });
    }

    subscribeToNotifications(): void {
        this.showLoader()
        this.sub.sink = this.notification
            .setUpPushNotification()
            .subscribe(_ => {
                this.subscribedToNotifications = true
                this.loader.hide();
            });
    }


    async showLoader() {
        this.loader.show();
        await this.waitFor10Seconds();
        this.loader.hide();
    }

    async waitFor10Seconds() {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                resolve();
            }, 10000);
        });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
        clearInterval(this.intervalLoop);
    }
}