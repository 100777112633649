<div [class]="formShow">

    <div [class]="category">
        <h2 class="subtopic-header" id="chosen-category" #chosenCategory>{{categoryTitle}}</h2>
    </div>
    <form id="submit-information" name="submit-information" (ngSubmit)="submitReport(reportSubmitForm)" #reportSubmitForm="ngForm">
        <div id="report-form-content" [class]="category">
            <!-- <input [(ngModel)]="formData.category" type="hidden" name="incident-category" id="incident-category" [value]="category">
            <input [(ngModel)]="formData.fullname" type="hidden" name="txt-full-name" id="txt-full-name">
            <input [(ngModel)]="formData.email" type="hidden" name="txt-email" id="txt-email"> -->
            <div id="report-form-subcontainer">
                <div>
                    <label class="small-white-label" for="incident-details">Details</label>
                    <textarea ngModel class="wide-white-input" id="incident-details" name="incident-details" placeholder="Give a description of the incident..." required></textarea>
                </div>
                <div *ngIf="this['suspicious-vehicle']">
                    <label class="small-white-label" for="incident-occupants-description">Occupants description</label>
                    <textarea ngModel class="wide-white-input" id="incident-occupants-description" name="incident-occupants-description" required></textarea>
                </div>
                <div *ngIf="this['suspicious-vehicle']">
                    <label class="small-white-label" for="incident-vehicle-make">Make of vehicle</label>
                    <input ngModel class="wide-white-input" id="incident-vehicle-make" name="incident-vehicle-make" type="text" required>
                </div>
                <div *ngIf="this['suspicious-vehicle']">
                    <label class="small-white-label" for="incident-vehicle-model">Model of vehicle</label>
                    <input ngModel class="wide-white-input" id="incident-vehicle-model" name="incident-vehicle-model" type="text" required>
                </div>
                <div *ngIf="this['suspicious-vehicle']">
                    <label class="small-white-label" for="incident-vehicle-colour">Colour of vehicle</label>
                    <input ngModel class="wide-white-input" id="incident-vehicle-colour" name="incident-vehicle-colour" type="text" required>
                </div>
                <div *ngIf="this['suspicious-vehicle']">
                    <label class="small-white-label" for="incident-vehicle-registration">Vehicle registration</label>
                    <input ngModel class="wide-white-input" id="incident-vehicle-registration" name="incident-vehicle-registration" type="text" required>
                </div>
                <div *ngIf="this['suspicious-person']">
                    <label class="small-white-label" for="incident-person-descripton">Description of person(s)</label>
                    <textarea ngModel class="wide-white-input" id="incident-person-descripton" name="incident-person-descripton" required></textarea>
                </div>
                <div *ngIf="this['protest-action']">
                    <label class="small-white-label" for="incident-violence-observed">Violence observed</label>
                    <select ngModel class="wide-white-input" id="incident-violence-observed" name="incident-violence-observed" required>
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </select>
                </div>
                <div *ngIf="this['theft']">
                    <label class="small-white-label" for="incident-property-type">Property type</label>
                    <select ngModel class="wide-white-input" id="incident-property-type" name="incident-property-type" required>
                        <option value="absa">Absa owned</option>
                        <option value="private">Private property</option>
                    </select>
                </div>
                <div *ngIf="this['malicious-damage-to-property']">
                    <label class="small-white-label" for="incident-property-type">Property type</label>
                    <select ngModel class="wide-white-input" id="incident-property-type" name="incident-property-type" required>
                        <option value="absa">Absa owned</option>
                        <option value="private">Private property</option>
                    </select>
                </div>
                <div *ngIf="this['smash-and-grab']">
                    <label class="small-white-label" for="incident-property-type">Property type</label>
                    <select ngModel class="wide-white-input" id="incident-property-type" name="incident-property-type" required>
                        <option value="absa">Absa owned</option>
                        <option value="private">Private property</option>
                    </select>
                </div>
                <div *ngIf="this['robbery']">
                    <label class="small-white-label" for="incident-property-type">Property type</label>
                    <select ngModel class="wide-white-input" id="incident-property-type" name="incident-property-type" required>
                        <option value="absa">Absa owned</option>
                        <option value="private">Private property</option>
                    </select>
                </div>
                <div *ngIf="this['theft']">
                    <label class="small-white-label" for="incident-property-ownership">Ownership</label>
                    <select ngModel class="wide-white-input" id="incident-property-ownership" name="incident-property-ownership" required>
                        <option value="absa">Absa</option>
                        <option value="mine">Mine</option>
                        <option value="3rd-party">3rd party</option>
                    </select>
                </div>
                <div *ngIf="this['malicious-damage-to-property']">
                    <label class="small-white-label" for="incident-property-ownership">Ownership</label>
                    <select ngModel class="wide-white-input" id="incident-property-ownership" name="incident-property-ownership" required>
                        <option value="absa">Absa</option>
                        <option value="mine">Mine</option>
                        <option value="3rd-party">3rd party</option>
                    </select>
                </div>
                <div *ngIf="this['smash-and-grab']">
                    <label class="small-white-label" for="incident-property-ownership">Ownership</label>
                    <select ngModel class="wide-white-input" id="incident-property-ownership" name="incident-property-ownership" required>
                        <option value="absa">Absa</option>
                        <option value="mine">Mine</option>
                        <option value="3rd-party">3rd party</option>
                    </select>
                </div>
                <div *ngIf="this['robbery']">
                    <label class="small-white-label" for="incident-property-ownership">Ownership</label>
                    <select ngModel class="wide-white-input" id="incident-property-ownership" name="incident-property-ownership" required>
                        <option value="absa">Absa</option>
                        <option value="mine">Mine</option>
                        <option value="3rd-party">3rd party</option>
                    </select>
                </div>
                <div *ngIf="this['vehicle-accident']">
                    <label class="small-white-label" for="incident-property-ownership">Vehicle Ownership</label>
                    <select ngModel class="wide-white-input" id="incident-vehicle-ownership" name="incident-vehicle-ownership" required>
                        <option value="absa">Absa</option>
                        <option value="mine">Mine</option>
                        <option value="3rd-party">3rd party</option>
                    </select>
                </div>
                <div *ngIf="this['assault']">
                    <label class="small-white-label" for="incident-who">Who</label>
                    <select ngModel class="wide-white-input" id="incident-who" name="incident-who" required>
                        <option value="me">Me</option>
                        <option value="colleague">Colleague</option>
                        <option value="3rd-party">3rd party</option>
                    </select>
                </div>
                <div *ngIf="this['vehicle-accident']">
                    <label class="small-white-label" for="incident-location">At Absa location</label>
                    <select ngModel class="wide-white-input" id="incident-location" name="incident-location" required>
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </select>
                </div>

                <ul id="picture-container" #pictureContainer [class]="pictureContainerClass" (change)="updateImageDisplay()">
                    <li *ngFor="let image of images">
                        <img [src]="getImageSource(image)">
                        <a href="#" class="delete-picture" (click)="deletePicture($event, image)"></a>
                    </li>
                </ul>

                <div id="picture-button-container">
                    <label class="picture-button" id="take-picture-label" for="take-picture">Take a picture</label>
                    <input type="file" id="take-picture" accept="image/*" capture="camera" name="button-take-picture" (change)="updateImages($event)" multiple>
                    <label class="picture-button" id="upload-picture-label" for="upload-picture">Upload a picture</label>
                    <input type="file" id="upload-picture" accept="image/*" name="button-take-picture" (change)="updateImages($event)" multiple>
                    <!-- <button type="button" class="picture-button" id="button-take-picture" name="button-take-picture" (click)="openCamera()">Take a picture</button> -->
                    <!-- <button type="button" class="picture-button" id="button-get-picture" name="button-get-picture" (click)="takePicture()">Upload a picture</button> -->

                </div>
                <label class="small-white-label small-black-label" for="num-latitude">Latitude</label>
                <p class="wide-white-input" id="num-latitude" type="number" readonly>{{latitude}}</p>
                <label class="small-white-label small-black-label" for="num-longitude">Longitude</label>
                <p class="wide-white-input" id="num-longitude" type="number" readonly>{{longitude}}</p>
                <label class="small-white-label small-black-label" for="txt-general-location">Location of incident</label>
                <input ngModel class="wide-white-input location-input last-location-input" name="txt-general-location" id="txt-general-location" type="text" required>
                <label for="datetime-datetime" class="small-black-label">Date and time of incident</label>
                <input ngModel id="datetime-datetime" type="datetime-local" class="wide-white-input" name="datetime-datetime" required>
                <button type="submit" id="information-submit" class="submit-button" [disabled]="!reportSubmitForm.valid">Submit</button>
                <button type="reset" routerLink="" id="information-submit-cancel" class="cancel-button">Cancel</button>
            </div>
        </div>
    </form>
</div>