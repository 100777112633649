import { Component } from '@angular/core';
import { CheckLocationService } from '../check-location.service';

@Component({
  selector: 'app-check-location',
  templateUrl: './check-location.component.html',
  styleUrls: ['./check-location.component.css']
})

export class CheckLocationComponent {
  public isAuthenticated: boolean = true;

  constructor(
    public checkLocation: CheckLocationService
  ) { }

  ngOnInit(): void {
    this.checkLocation.isLocationEnabled()
  }

}
