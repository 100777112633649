import { Component, OnInit } from '@angular/core';
import { ApiService } from "../api.service";
import { LogoutService } from '../logout.service';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
    selectedCategory: string = '';
    chooserShow: string = 'visible';
    formShow: string = 'hidden';
    category: string = '';
    categoryTitle: string = '';
    intervalLoop: any;

    constructor(
        private api: ApiService,
        private logoutService: LogoutService
    ) {
        this.intervalLoop = setInterval(() => {
            this.checkAccountStatus(); 
        }, this.logoutService.getCheckAccountStatusTimer());
    }

    ngOnInit(): void {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        }
    }

    ngOnDestroy() {
        clearInterval(this.intervalLoop);
    }

    checkAccountStatus() {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        }
    }

    chooseCategory(category: string) {
        this.chooserShow = 'hidden';
        this.formShow = 'visible';
        this.category = category;
        this.categoryTitle = category.replace(/-/g, ' ').replace(/^\w/, c => c.toUpperCase());
    }

    isTokenValid() {
        const error_code_arr = [401, 403];
        this.api.fetchSettings().subscribe({
            next: (data) => {
                if (data.message == 'token invalid') {
                    this.logoutService.logout();
                }
            },
            error: (error) => {
                error_code_arr.includes(error.status) ? this.logoutService.logout() : console.log(error);
            }
        });
    }
}
