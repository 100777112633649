// import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { UserIdleService } from 'angular-user-idle';
import { delay } from 'rxjs/operators';
import { fromEvent, Subject } from "rxjs";
// import { PushNotificationService } from './push-notification.service';
import { LogoutService } from './logout.service';
import { LoadingService } from './loading.service';
import { SocketService } from './socket.service';
import { ApiService } from './api.service';
import { Map } from 'leaflet';
import { LoaderService } from './loader.service';
import { Observable } from 'rxjs';
import { CheckLocationService } from './check-location.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    isLoggedIn: boolean;
    loading: boolean = false;
    title = 'Stay Safe';
    loadingMessage: Observable<string>;
    public isAuthenticated = false;

    constructor(
        private loader: LoaderService,
        private userIdle: UserIdleService,
        private router: Router,
        private swPush: SwPush,
        private api: ApiService,
        private loadingService: LoadingService,
        private socketService: SocketService,
        private logoutService: LogoutService,
        public checkLocation: CheckLocationService,
        // private pushService: PushNotificationService
    ) {
        fromEvent(document, 'mousemove').subscribe(() => this.restart());
        fromEvent(document, 'touchstart').subscribe(() => this.restart());
        fromEvent(document, 'keydown').subscribe(() => this.restart());
    }

    ngOnInit() {
        this.isTokenValid()
        if (localStorage.getItem('id') === null && localStorage.getItem('jwt') === null) {
            this.router.navigate(['/login']);
        } else {

            if (!this.isMobile()) {

                //Start watching for user inactivity.
                this.userIdle.startWatching();

                // Start watching when user idle is starting.
                this.userIdle.onTimerStart().subscribe(count => '');

                // Start watch when time is up.
                this.userIdle.onTimeout().subscribe(() => this.onTimerLimit());

            }

            this.listenToLoading();

            if (this.swPush.isEnabled) {
                // fetch the vapid public key from the server
                this.api.fetchVapidKey().subscribe(resultData => {
                    if (resultData.success) {
                        this.swPush.requestSubscription({
                            serverPublicKey: resultData.extras.key
                        }).then(subscription => {

                            let result = this.api.sendPushSubscription(subscription).subscribe();

                        }).catch((err) => {
                            this.socketService.getData().subscribe((data: any) => {
                                if (data.hasOwnProperty('id')) {
                                    // we are sending the id to the server
                                    this.api.sendSocketId(data.id).subscribe(result => {
                                    });
                                } else {
                                    // we are showing a notifications
                                    this.showSocketNotification(data);
                                }
                            });
                        });
                    }
                });
            }
        }

        this.swPush.notificationClicks.subscribe(({ action, notification }) => {
            this.api.markAlertAsReceived(notification.data.alertId).subscribe();
            this.router.navigate(['/alerts']);
        });
    }

    isMobile() {
        const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        return regex.test(navigator.userAgent);
    }

    stop() {
        this.userIdle.stopTimer();
    }

    stopWatching() {
        this.userIdle.stopWatching();
    }

    startWatching() {
        this.userIdle.startWatching();
    }

    restart() {
        this.userIdle.resetTimer();
    }

    onTimerLimit() {
        this.stop;
        this.stopWatching;
        this.logoutService.logout();
    }

    showSocketNotification(data): void {
        let notification = JSON.parse(data);
        let title = notification.notification.title;
        let options = {
            badge: notification.notification.badge,
            body: notification.notification.body,
            data: notification.notification.data,
            dir: notification.notification.dir,
            icon: notification.notification.icon,
            image: notification.notification.image,
            lang: notification.notification.lang,
            renotify: notification.notification.renotify,
            requireInteraction: notification.notification.requireInteraction,
            silent: notification.notification.silent,
            tag: notification.notification.tag,
            timestamp: notification.notification.timestamp,
            vibrate: notification.notification.vibrate
        };
        navigator.serviceWorker.ready.then(serviceWorker => {
            serviceWorker.showNotification(title, options);
        });
        this.api.markAlertAsReceived(notification.notification.data.alertId).subscribe(result => {
        });
    }

    isTokenValid() {
        this.api.fetchSettings().subscribe({
          next: (data) => {
            data.message == 'token invalid' ? this.isAuthenticated = false : this.isAuthenticated = true;
          },
          error: (error) => {
          }
        });
      }

    listenToLoading(): void {
        this.loadingService.loadingSub.pipe(delay(0)).subscribe((loading) => {
            this.loading = loading;
        });
    }
}
