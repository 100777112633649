import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-news-feed-footer',
  templateUrl: './news-feed-footer.component.html',
  styleUrls: ['./news-feed-footer.component.css']
})
export class NewsFeedFooterComponent {
  @Input() page: string = '';
  activeClass: string = '';
  homeActive: boolean = false;
  settingsActive: boolean = false;
  refreshActive: boolean = false;
  sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  goBack(event) {
    event.preventDefault();
    window.history.back();
}

goForward(event) {
    event.preventDefault();
    window.history.forward();
}

changeColour() {
    this.processChangeColour();
}

async processChangeColour() {
    this.refreshActive = true;
    await this.sleep(200);
    this.refreshActive = false;
}
}
