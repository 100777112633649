import { Component, Output, EventEmitter, OnInit, Input, QueryList, ViewChildren } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { MapService } from 'src/app/map.service';
import { LogoutService } from '../../logout.service';

@Component({
    selector: 'app-alerts-display',
    templateUrl: './alerts-display.component.html',
    styleUrls: ['./alerts-display.component.css']
})
export class AlertsDisplayComponent implements OnInit {
    @Output() showMapEvent = new EventEmitter<string>();
    @Input() alertsData: object;
    // alertsData: object;
    @ViewChildren('alertToggles') checkboxes!: QueryList<any>;
    hideAlerts: boolean = false;
    intervalLoop: any;

    constructor(
        private api: ApiService,
        private map: MapService,
        private logoutService: LogoutService
    ) {
        this.map.showAlerts$.subscribe(status => {
            if (status) {
                this.hideAlerts = false;
            } else {
                this.hideAlerts = true;
            }
        });
        this.intervalLoop = setInterval(() => {
            this.checkAccountStatus(); 
        }, this.logoutService.getCheckAccountStatusTimer());
    }

    ngOnInit(): void {
        this.isTokenValid()
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        }
    }
    
    ngOnDestroy() {
        clearInterval(this.intervalLoop);
    }

    checkAccountStatus() {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        }
    }

    showAlertMap(alertId: number) {
        this.api.getAlerts(alertId).subscribe(resultData => {
            if (resultData.success) {
                this.showMapEvent.emit('alert');
                this.map.data = resultData;
                this.hideAlerts = true;
            }
        });
    }

    toggleCheckbox(event: any) {
        this.checkboxes.forEach(checkbox => {
            if (event.target !== checkbox.nativeElement) {
                if (checkbox.nativeElement.checked === true) {
                    checkbox.nativeElement.checked = false;
                }
            }
        });

    }
    
    isTokenValid() {
        const error_code_arr = [401, 403];
        this.api.fetchSettings().subscribe({
            next: (data) => {
                if (data.message == 'token invalid') {
                    this.logoutService.logout();
                }
            },
            error: (error) => {
                error_code_arr.includes(error.status) ? this.logoutService.logout() : console.log(error);
            }
        });
    }
}
