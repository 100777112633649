import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { SequenceCall } from '../../interfaces/sequence-call';
import { ApiService } from '../../api.service';
import { LogoutService } from '../../logout.service';
import { timer } from 'rxjs';
@Component({
  selector: 'app-slide-show',
  templateUrl: './slide-show.component.html',
  styleUrls: ['./slide-show.component.css',],
  animations: [
    trigger('showHide', [
      state('visible', style({
        opacity: 1,
      })),
      state('hidden', style({
        opacity: 0,
      })),
      transition('visible => hidden', [
        animate('1s', style({ opacity: 0 })),
        animate('1s', style({ opacity: 1 })),
        animate('1s', style({ opacity: 0 })),
        animate('1s', style({ opacity: 0 })),
        animate('1s', style({ opacity: 0 })),
      ]),
      transition('hidden => visible', [
        animate('1s', style({ opacity: 1 })),
        animate('1s', style({ opacity: 1 })),
        animate('1s', style({ opacity: 0 })),
        animate('1s', style({ opacity: 0 })),
      ]),
    ]),
    trigger('closeContainer', [
      state('open', style({
        opacity: 1,
      })),
      state('closed', style({
        opacity: 0,
      })),
      transition('open => closed', [
        animate('1s')
      ])
    ]
    )
  ]
})
export class SlideShowComponent {
  @Input() show: boolean = false;
  @Output() doneEvent = new EventEmitter<string>();
  message1Open = false;
  message2Open = false;
  containerOpen = true;
  hide1 = false;
  hide2 = true;
  hideContainer = false;
  timer = 3000;
  timeout = 0;
  seq: Array<SequenceCall>;

  constructor(
    private api: ApiService,
    private logoutService: LogoutService
  ) { }

  ngOnInit() {
    this.isTokenValid();
    this.callSequence();
  }

  // slideshow display sequence
  sequence: Array<SequenceCall> = [
    {
      call: () => {
        this.message1Open = !this.message1Open;
      },
      timeout: this.timeout
    },
    {
      call: () => {
        this.hide1 = !this.hide1;
        this.hide2 = !this.hide2;
        this.message2Open = !this.message2Open;
      },
      timeout: this.timeout += this.timer
    },
    {
      call: () => {

        this.containerOpen = !this.containerOpen;
      },
      timeout: this.timeout += this.timer
    },
    {
      call: () => {
        this.hideContainer = !this.hideContainer;
      },
      timeout: this.timeout += 1000,
    },
    {
      call: () => {
        this.doneEvent.emit('done');
      }, timeout: this.timeout += 1000,
    }
  ];

  callSequence() {
    this.sequence.map((data, index) => {
      setTimeout(() => {
        data.call();
      }, data.timeout)
    });
  }

  // checks if authentication token is valid
  isTokenValid() {
    const error_code_arr = [401, 403];
    this.api.fetchSettings().subscribe({
      next: (data) => {
        if (data.message == 'token invalid') {
          this.logoutService.logout();
        }
      },
      error: (error) => {
        error_code_arr.includes(error.status) ? this.logoutService.logout() : console.log(error);
      }
    });
  }
}
