import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckLocationService {

  public isLocationOn = false;
  constructor() { }

  ngOnInit(): void {
    this.isLocationEnabled()
  }
  
  isLocationEnabled(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.isLocationOn = true;
            resolve(true);
          },
          (error) => {
            this.isLocationOn = false;
            // Location is not enabled or user denied permission
            resolve(false);
          }
        );
      } else {
        this.isLocationOn = false;
        // Geolocation API is not available
        resolve(false);
      }
    });
  }

  delay(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  }

  // async  showPrompt() {
  //   await this.delay(6000); // Wait for 3 seconds
  //   this.isLocationEnabled()
  // }
  
}
