<div id="information-report">
    <div class="page-header-block">
        <h1 class="page-header">Log report</h1>
    </div>
    <app-check-location></app-check-location>
    <!-- /header -->
    <div id="ctn-notify"></div>
    <div id="ctn-notify-error"></div>
    <div id="information-map-container" class="map-container" style="position:relative; display: none;">
        <div id="information-map-container-info-window">
            <a href="#" class="wide-white-input close-map-button" id="use-my-location">Use My Location</a>
            <a href="#" class="wide-white-input close-map-button" id="hide-map">Close Map</a>
        </div>
    </div>
    <div role="main" class="ui-content">
        <div id="report-header-sub">
            <h2 id="secondary-report-header" class="secondary-header secondary-header-no-icon">Type of report</h2>
        </div>
        <div [class]="chooserShow">
            <p class="tagline category-chooser" id="category-tagline">Select the category of the report you are logging</p>
            <div class="grid" id="category-chooser">
                <div>
                    <div (click)="chooseCategory('assault')" id="type-assault" class="ui-icon-type-assault incident-category incident-category-active">Assault</div>
                </div>
                <div>
                    <div (click)="chooseCategory('malicious-damage-to-property')" id="type-malicious-damage" class="ui-icon-type-malicious-damage incident-category incident-category-active">Malicious damage to property</div>
                </div>
                <div>
                    <div (click)="chooseCategory('protest-action')" id="type-protest-action" class="ui-icon-type-protest-action incident-category incident-category-active">Protest action</div>
                </div>
                <div>
                    <div (click)="chooseCategory('robbery')" id="type-robbery" class="ui-icon-type-robbery incident-category incident-category-active">Robbery</div>
                </div>
                <div>
                    <div (click)="chooseCategory('smash-and-grab')" id="type-smash-grab" class="ui-icon-type-smash-grab incident-category incident-category-active">Smash and grab</div>
                </div>
                <div>
                    <div (click)="chooseCategory('suspicious-person')" id="type-suspicious-person" class="ui-icon-type-suspicious-person incident-category incident-category-active">Suspicious person</div>
                </div>
                <div>
                    <div (click)="chooseCategory('suspicious-vehicle')" id="type-suspicious-vehicle" class="ui-icon-type-suspicious-vehicle incident-category incident-category-active">Suspicious vehicle</div>
                </div>
                <div>
                    <div (click)="chooseCategory('syndicate-approach')" id="type-syndicate-approach" class="incident-category incident-category-active ui-icon-type-syndicate-approach">Syndicate approach</div>
                </div>
                <div>
                    <div (click)="chooseCategory('theft')" id="type-theft" class="incident-category incident-category-active ui-icon-type-theft">Theft</div>
                </div>
                <div>
                    <div (click)="chooseCategory('vehicle-accident')" id="type-vehicle-accident" class="incident-category incident-category-active ui-icon-type-vehicle-accident">Vehicle accident</div>
                </div>
                <div>
                    <a href="tel:+27118957777" id="type-work-related-emergency" data-number="+27118957777" class=" incident-category incident-category-active ui-icon-type-work-related-emergency">Work related emergency</a>
                </div>
                <div>
                    <div id="type-" class="incident-category"></div>
                </div>
            </div>
        </div>
        <app-report-form [formShow]="formShow" [category]="category" [categoryTitle]="categoryTitle"></app-report-form>
        <p class="small-margin-paragraph indent-paragraph copyright-container">
            &copy; Copyright TopicWorx
        </p>
    </div>
    <!-- /content -->
    <app-footer></app-footer>
</div>