<ul id="alerts-container" class="list-no-left-padding" [class.hidden]="hideAlerts">
    <li *ngFor="let alert of alertsData" id="{{alert.articleId}}" class="wide-white-input">
        <input id="checkbox-{{alert.articleId}}" type="checkbox" name="checkbox-{{alert.articleId}}" (change)="toggleCheckbox($event)" #alertToggles>
        <label for="checkbox-{{alert.articleId}}">
            <h4 class="alert-header text-wrap">
                {{alert.articleDistance}} km away: {{alert.articleTitle | slice:0:40}}...
            </h4>
        </label>
        <div class="ui-collapsible-content">
            <button class="update-map-button" id="view-on-map-{{alert.articleId}}" (click)="showAlertMap(alert.articleId)">View alert on map</button>
            <p class="alert-title">{{alert.articleDistance}} km away: {{alert.articleTitle}}</p>
            <p>{{alert.articleContent}}</p>
        </div>
    </li>
</ul>