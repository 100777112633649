<div id="menu">
    <app-slide-show [show]="showWelcomeVal" (doneEvent)="welcomeFinished($event)"></app-slide-show>
    <div class="page-header-block">
        <h1 class="page-header">Menu</h1>
    </div>
    <app-check-location></app-check-location>
    <!-- /header -->
    <div id="ctn-notify"></div>
    <div id="ctn-notify-error"></div>
    <div class="ui-content">
        <ul data-role="listview" class="button-list">
            <li>
                <a routerLink="/report" class="ui-icon-log-report home-menu-link" id="log-report">Log report<span class="ui-icon-arrow-right"></span></a>
            </li>
            <li>
                <a routerLink="/alerts" class="ui-icon-view-nearby-alerts home-menu-link" id="view-local-alerts">View alerts in my radius<span class="ui-icon-arrow-right"></span></a>
            </li>
            <li>
                <a routerLink="/maps" class="ui-icon-view-maps home-menu-link" id="btn-view-maps">View maps<span class="ui-icon-arrow-right"></span></a>
            </li>
            <li>
                <a routerLink="/video-repository" class="ui-icon-video-repository home-menu-link" id="view-local-alerts">Videos<span class="ui-icon-arrow-right"></span></a>
            </li>
            <li>
                <a routerLink="/hijack-hotspots" class="ui-icon-hijack_hotspots home-menu-link" id="view-hijack_hotspots">Hijack Hotspots<span class="ui-icon-arrow-right"></span></a>
            </li>
            <li>
                <a routerLink="/faq-tab" class="ui-icon-faqs home-menu-link">Frequently asked questions<span class="ui-icon-arrow-right"></span></a>
            </li>
            <li>
                <a routerLink="/settings" class="ui-icon-settings home-menu-link">Settings<span class="ui-icon-arrow-right"></span></a>
            </li>
            <li class="last">
                <a [routerLink]="" (click)="logout()" class="ui-icon-logout home-menu-link" id="btn-logout">Logout<span class="ui-icon-arrow-right"></span></a>
            </li>
        </ul>

        <div *ngIf="!this.installBtn.isPwaInstalled()">

            <div *ngIf="isIos == true">
                <div class="alert install-container" [style.visibility]="this.installBtn.hideToolTip ? 'hidden': 'visible'">
                    <span class="closebtn" (click)="hideToolTip()">&times;</span> To install this app: tap <img width="25px" src="../../assets/images/ios-share.svg"> and then add to home screen
                </div>
            </div>

            <div *ngIf="this.installBtn.status && isIos == false">
                <div>
                    <div class="install-container">
                        <button id="btn-install" class="submit-button auto-height" (click)="this.installBtn.installPwa()">
                            Install the app
                        </button>
                    </div>
                </div>
            </div>

        </div>
        <p class="small-margin-paragraph indent-paragraph copyright-container">
            &copy; Copyright TopicWorx
        </p>
    </div>
    <app-footer [page]=pageName></app-footer>
</div>