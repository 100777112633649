<div id="login" *ngIf="sent">
    <img class="absa-logo" src="../../assets/images/red_absa_logo.svg" alt="">
    <div class="alert alert-danger response-alert" role="alert">
        We have sent password reset QR code to your email address
    </div>

    <div id="ctn-notify"></div>
    <div id="ctn-notify-error"></div>
    <div class="ui-content">
        <button [routerLink]="['/', 'login']" class="submit-button login-button">
            Go to login page
        </button>
        <p class="small-margin-paragraph indent-paragraph copyright-container bottom-copyright-container">
            &copy; Copyright TopicWorx
        </p>
    </div>
</div>

<div id="login" *ngIf="!sent" class="d-flex justify-content-center flex-nowrap">
    <div id="ctn-notify"></div>
    <div id="ctn-notify-error"></div>
    <div class="ui-content">
        
        <div class="page-header-block">
            <h1 class="page-header">Forgot Password...</h1>
        </div>

        <form id="loginForm" [formGroup]="form">
            <div class="large-input-container form-group">
                <label class="large-red-input-label" id="login-username" for="loginEmail"><!-- --></label>
                <input class="large-white-input form-control"
                       placeholder="Email Address"
                       formControlName="email">
            </div>
            <div>
                <button type="submit"
                        id="login-button"
                        class="solid-light-red-button ui-btn ui-btn-b ui-corner-all mc-top-margin-1-5"
                        [disabled]="!form.valid"
                        (click)="sendResetPasswordLink()">
                        Send
                    </button>
            </div>
            <div class="extra-top-margin login-page-extra">
                <p class="small-margin-paragraph indent-paragraph">
                    I remember my credentials?
                    <a [routerLink]="['/', 'login']" id="register-link">Login.</a>
                </p>
            </div>
        </form>
        <p class="small-margin-paragraph indent-paragraph copyright-container bottom-copyright-container">
            &copy; Copyright TopicWorx
        </p>
    </div>
</div>
