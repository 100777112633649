// import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core'
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr'
import { ApiService } from '../api.service'
import { SubSink } from 'subsink';
import { tap } from 'rxjs';
import { LoaderService } from 'src/app/loader.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    formData: any = {}
    registerResult: any
    public showRegister: boolean = true
    public showRegisterSuccess: boolean = false
    public showDisclaimer: boolean = false
    public showPrivacyNotice: boolean = false
    public showUsagePolicy: boolean = false
    //expiry time in minutes
    registerCookieExpiryTime: any = 5;
    registerCounter: any = 0;
    registerAttemptLimit: any = 3;
    isRegisterAttemptsReached: boolean = false;
    //interval in seconds
    checkCookieInterval: any = 1;
    private sub = new SubSink()

    constructor(private loader: LoaderService,private toastr: ToastrService, private api: ApiService, private cookieService: CookieService) { }

    ngOnInit(): void {
        this.checkLoginAttemptCookie();
    }

    showExtras(event, display: string) {
        event.preventDefault()
        this[display] = true
    }

    closeExtras(event, display: string) {
        event.preventDefault()
        this[display] = false
    }
    
    isValidEmail(email) {
        const trimmedEmail = email.trim();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(trimmedEmail);
    }

    pwdCheck(password) {
        let hasNumber = /[0-9]/.test(password)
        let hasCapitalLetter = /[A-Z]/.test(password)
        let hasSmallLetter = /[a-z]/.test(password)
        let hasSpecialCharacter = /[^a-zA-Z0-9]/.test(password)
        if (password.length >= 8 && hasNumber && hasCapitalLetter && hasSmallLetter && hasSpecialCharacter) {
            return true;
        } else {
            return false;
        }
    }

    register() {
        this.loader.show();
        if (this.registerCounter >= this.registerAttemptLimit) {
            this.loader.hide();
            this.setRegisterCookie('register_attempts', this.registerCounter, this.registerCookieExpiryTime);
            this.onDisableInputClick();
            this.lockAccount();
            this.sendSuspiciousActivityEmail()
        }

        if (this.registerCounter < this.registerAttemptLimit) {

            if (
                this.formData.registrationPassword !==
                this.formData.registrationPasswordConfirm
            ) {
                this.loader.hide();
                this.toastr.error("Passwords do not match", '', {
                    toastClass: 'absa-toast'
                })
            }

            if(!this.isValidEmail(this.formData.registrationEmail)){
                this.loader.hide();
                this.toastr.error(
                    `The email address you are using is not valid.`,
                    '',
                    { toastClass: 'absa-toast' }
                )
            }

            if (!this.pwdCheck(this.formData.registrationPassword)) {
                this.loader.hide();
                this.toastr.error(
                    `Your password is not complex enough. 
                Passwords must be a least 8 characters long, 
                contain both upper and lowercase characters, 
                at least one number and at least one special character.
                `,
                    '',
                    { toastClass: 'absa-toast' }
                )
            }

            if (
                this.pwdCheck(this.formData.registrationPassword) &&
                this.formData.registrationPassword ===
                this.formData.registrationPasswordConfirm &&
                this.isValidEmail(this.formData.registrationEmail)
            ) {
                this.api
                    .register({
                        firstName: this.formData.registrationFirstName,
                        lastName: this.formData.registrationLastName,
                        contactNumber: this.formData.registrationTel,
                        email: this.formData.registrationEmail.replace(/ /g,''),
                        password: this.formData.registrationPassword
                    })
                    .subscribe(resultData => {
                        this.loader.hide();
                        this.registerResult = resultData
                        if (this.registerResult.success) {
                            this.showRegister = false
                            this.showRegisterSuccess = true
                        } else {
                            this.loader.hide();
                            let message = ''
                            if (this.registerResult.extras.msg) {
                                switch (this.registerResult.extras.msg) {
                                    case 4:
                                        message =
                                            'The email address that you provided is already registered.'
                                        break
                                    case 12:
                                        message = 'The email address that you are using is not valid.'
                                        break
                                    default:
                                        message =
                                            'Oops! We were unable to register you at this time.  Please try again in a few minutes.'
                                        break
                                }
                            } else {
                                this.loader.hide();
                                message =
                                    'Oops! We were unable to register you at this time.  Please try again in a few minutes.'
                            }

                            this.registerCounter += 1;
                            this.toastr.error(message, '', { toastClass: 'absa-toast' })

                        }
                    })
            }
        }
    }

    setRegisterCookie(cookieName: string, cookieValue: string, expiryMinutes: number) {
        const date = new Date();
        date.setTime(date.getTime() + (expiryMinutes * 60 * 1000));
        this.cookieService.set(cookieName, cookieValue, { expires: date });
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async lockAccount() {
        await this.sleep(this.registerCookieExpiryTime * 60 * 1000);
        this.registerCounter = 0;
    }

    onDisableInputClick() {
        this.toastr.error(`Please wait a few minutes before you try again`, '', { toastClass: 'absa-toast' });
    }

    checkLoginAttemptCookie() {
        setInterval(() => {
            if (this.cookieService.get('register_attempts')) {
                if (parseInt(this.cookieService.get('register_attempts')) >= this.registerAttemptLimit) {
                    this.isRegisterAttemptsReached = true;
                }
            } else {
                this.isRegisterAttemptsReached = false;
            }
        }, this.checkCookieInterval * 1000);
    }
    sendSuspiciousActivityEmail(): void {
        this.sub.sink = this.api
            .suspiciousActivityEmail(this.formData.registrationEmail, 'register')
            .pipe(tap({ next: _ => console.log(''), error: _ => console.log('Suspicious activity email error!!!') }))
            .subscribe()
    }

}
