import { Component, Output, EventEmitter, OnInit, Input, QueryList, ViewChildren } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoaderService } from 'src/app/loader.service';
import { faqs } from "./faqs.object";
@Component({
  selector: 'app-faq-tab',
  templateUrl: './faq-tab.component.html',
  styleUrls: ['./faq-tab.component.css']
})
export class FaqTabComponent {

  pdfSrc: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private loader: LoaderService,
  ) {
    this.pdfSrc = '../../../assets/faq-docs/Stay-Safe-selling-points-and-FAQs.pdf';
  }

  loadingImg() {
    this.loader.show();
  }

}
