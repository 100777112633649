import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { GeolocationService } from '@ng-web-apis/geolocation';

import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    httpOptions: object = {
        headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        })
    };
    httpOptionsJson: object = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    httpSecureOptions: object = {
        headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + localStorage.getItem('jwt')
        })
    };

    baseUrl: string = 'https://api-absa.topicworx.co.za/'; 
    // baseUrl: string = 'http://api-absa.test/'; 

    constructor(
        private http: HttpClient,
        private readonly geolocation$: GeolocationService
    ) { }

    login(data: object) {
        const loginUrl = this.baseUrl + 'pwa/account/login/';
        return this.http.post<any>(loginUrl, this.objectToUrlString(data), this.httpOptions);
    }

    register(data: object) {
        const registerUrl = this.baseUrl + 'pwa/account/register/';
        return this.http.post<any>(registerUrl, this.objectToUrlString(data), this.httpOptions);
    }

    getAlerts(alertId: number = null) {
        let alertsUrl: string = '';
        
        if (alertId !== null) {
            alertsUrl = this.baseUrl + 'pwa/articles/' + alertId + '?guid=' + localStorage.getItem('id');
        } else {
            alertsUrl = this.baseUrl + 'pwa/articles/?guid=' + localStorage.getItem('id');
        }
        return this.http.get<any>(alertsUrl, this.httpOptions);
    }

    fetchAtms() {
        let atmsUrl = this.baseUrl + 'pwa/account/atms/?guid=' + localStorage.getItem('id');
        return this.http.get<any>(atmsUrl, this.httpOptions);
    }

    fetchBranches() {
        let branchesUrl = this.baseUrl + 'pwa/account/branches/?guid=' + localStorage.getItem('id');
        return this.http.get<any>(branchesUrl, this.httpOptions);
    }

    fetchHijackHotspots(lat: number, lon: number) {
        let hijackHotspotsUrl = this.baseUrl + 'pwa/account/hijack/?guid=' + localStorage.getItem('id') + '&lat=' + lat + '&lon=' + lon;
        return this.http.get<any>(hijackHotspotsUrl, this.httpOptions);
    }

    fetchAllHijackHotspots() {
        let hijackHotspotsUrl = this.baseUrl + 'pwa/account/hijack/?guid=' + localStorage.getItem('id');
        return this.http.get<any>(hijackHotspotsUrl, this.httpOptions);
    }

    getAllAlerts() {
        let alertsUrl = this.baseUrl + 'pwa/articles/all?guid=' + localStorage.getItem('id');
        return this.http.get<any>(alertsUrl, this.httpOptions);
    }

    sendReport(data: any): any {
        let reportUrl = this.baseUrl + 'pwa/account/alerts/';
        data.guid = localStorage.getItem('id');
        data = this.convertData(data);
        return this.http.post<any>(reportUrl, this.objectToUrlString(data), this.httpOptions);
    }

    fetchSettings() {
        let settingsUrl: string = this.baseUrl + 'pwa/account/settings/?guid=' + localStorage.getItem('id');
        return this.http.get<any>(settingsUrl, this.httpOptions);
    }

    updateSettings(data: any): any {
        let settingsUrl: string = this.baseUrl + 'pwa/account/settings/';
        data.guid = localStorage.getItem('id');
        return this.http.put<any>(settingsUrl, this.objectToUrlString(data), this.httpOptions);
    }

    fetchPlaces(lat: number, lon: number, type: string): any {
        let placesUrl: string = this.baseUrl + 'pwa/account/places/?type=' + type + '&lat=' + lat + '&lon=' + lon + '&guid=' + localStorage.getItem('id');
        return this.http.get<any>(placesUrl, this.httpOptions);
    }

    convertData(data: any) {
        let dataString = '';
        Object.entries(data.data).map(([key]) => {
            data['data[' + key + ']'] = data.data[key];
        });
        delete data.data;
        return data;
    }

    fetchVapidKey() {
        let vapidUrl: string = this.baseUrl + 'pwa/account/push/key/?guid=' + localStorage.getItem('id');
        return this.http.get<any>(vapidUrl, this.httpOptions);
    }

    fetchLoginAttempts(email:string) {
        let loginAttemptsUrl: string = this.baseUrl + 'pwa/account/loginAttempts/?email='+ email;
        return this.http.get<any>(loginAttemptsUrl, this.httpOptions);
    }

    updateLoginAttempts(data: any): any {
        let loginAttemptsUrl: string = this.baseUrl + 'pwa/account/loginAttempts/';
        return this.http.put<any>(loginAttemptsUrl, this.objectToUrlString(data), this.httpOptions);
    }

    sendPushSubscription(subscription: any) {
        let subscriptionUrl: string = this.baseUrl + 'pwa/account/push/subscription/';
        return this.http.post<any>(subscriptionUrl, this.objectToUrlString({ subscription: JSON.stringify(subscription), guid: localStorage.getItem('id') }), this.httpOptions);
    }

    sendLocation() {
        let lon: number, lat: number;
        this.geolocation$.pipe(take(1)).subscribe({
            next(position) {
                lat = position.coords.latitude;
                lon = position.coords.longitude;
            },
            complete: () => {
                const locationUrl = this.baseUrl + 'pwa/account/location/';
                this.http.post<any>(locationUrl, this.objectToUrlString({ lat: lat, lon: lon, guid: localStorage.getItem('id') }), this.httpOptions).subscribe(result => {
                    return result;
                });
            }
        });
    }

    forgotPassword(email: string): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}pwa/account/forgotPassword`, {email: email});
    }

    suspiciousActivityEmail(email: string, page:string): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}pwa/account/suspiciousActivityEmail`, {email: email, page: page});
    }

    sendSocketId(id: string) {
        let socketUrl: string = this.baseUrl + 'pwa/account/socket/id/';
        return this.http.post<any>(socketUrl, this.objectToUrlString({ id: id, guid: localStorage.getItem('id') }), this.httpOptions);
    }

    checkPushSubscription() {
        let subscriptionUrl: string = this.baseUrl + 'pwa/account/push/subscription/?guid=' + localStorage.getItem('id');
        return this.http.get<any>(subscriptionUrl, this.httpOptions);
    }

    markAlertAsReceived(alertId: number) {
        let alertUrl: string = this.baseUrl + '/pwa/account/alerts/' + alertId;
        return this.http.post<any>(alertUrl, this.objectToUrlString({ guid: localStorage.getItem('id') }), this.httpOptions);
    }

    objectToUrlString(object: object) {
       return Object.entries(object).map(([key]) => `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`).join('&');
    }

}
