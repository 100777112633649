import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { menu } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { SubSink } from 'subsink';
import { tap } from 'rxjs';
import { LoaderService } from 'src/app/loader.service';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    formData: any = {};
    loginResult: any;
    loginCounter: any = 0;
    isAccountValid: boolean = true;
    isAccountActivated: boolean = true;
    private sub = new SubSink()
    //interval in seconds
    checkCookieInterval:any = 1;
    loginAttemptLimit:any = 3;
    //expiry time in minutes
    loginCookieExpiryTime:any = 5;
    IsLoginAttemptsReached: boolean = false;

    constructor(
        private loader: LoaderService,
        private router: Router,
        private toastr: ToastrService,
        private api: ApiService,
        private cookieService: CookieService
    ) { }

    ngOnInit() {
        menu.visited = false;
        this.checkLoginAttemptCookie();
    }

    setLoginCookie(cookieName: string, cookieValue: string, expiryMinutes: number) {
        const date = new Date();
        date.setTime(date.getTime() + (expiryMinutes * 60 * 1000));
        this.cookieService.set(cookieName, cookieValue, { expires: date });
    }

    login() {
        this.api.fetchLoginAttempts(this.formData.loginEmail).subscribe((resultData) => {
            if (resultData.success) {
                let dbLoginCount = parseInt(resultData.extras);
                if (dbLoginCount >= this.loginAttemptLimit || this.loginCounter >= this.loginAttemptLimit) {
                    this.setLoginCookie('login_attempts', this.loginCounter, this.loginCookieExpiryTime);
                    this.setLoginCookie('_login_attempts', dbLoginCount.toString() , this.loginCookieExpiryTime);
                    this.onDisableInputClick();
                    this.unlockAccount(this.formData.loginEmail);
                    this.sendSuspiciousActivityEmail();
                } else {
                    this.loginAccess();
                }
            }
        });
    }

    onDisableInputClick() {
        this.toastr.error(`Please wait a few minutes before you try again`, '', { toastClass: 'absa-toast' });
    }

    loginAccess() {
        this.loader.show();
        this.api.login({
            'email': this.formData.loginEmail,
            'password': this.formData.loginPassword
        }).subscribe(resultData => {
            this.loginResult = resultData;
            if (this.loginResult.success) {
                this.loader.hide();
                localStorage.setItem('id', this.loginResult.id);
                localStorage.setItem('jwt', this.loginResult.jwt);
                this.router.navigate(['']);
            } else {
                this.loader.hide();
                let message = '';
                if (this.loginResult.extras.msg) {
                    switch (this.loginResult.extras.msg) {
                        case 12:
                            message = 'Invalid login credentials.';
                            break;
                        case 13:
                            message = 'Invalid login credentials.';
                            break;
                        case 17:
                            message = 'Please activate your account as per the email you received. If you still get this message, please contact <a href="mailto:staysafe@absa.africa">admin</a>.';
                            this.isAccountActivated = false;
                            break;
                        default:
                            message = 'Oops! We were unable to log you in at this time.  Please try again in a few minutes.';
                            break;
                    }
                } else {
                    message = 'Oops! We were unable to log you in at this time.  Please try again in a few minutes.';
                }
                this.loginCounter += 1;
                this.toastr.error(message, '', { toastClass: 'absa-toast' });
            }
        });
    }

    checkLoginAttemptCookie() {
        setInterval(() => {
            if (this.cookieService.get('login_attempts') || this.cookieService.get('_login_attempts')) {
                if (parseInt(this.cookieService.get('login_attempts')) >= this.loginAttemptLimit || parseInt(this.cookieService.get('_login_attempts')) >= this.loginAttemptLimit ) {
                    this.IsLoginAttemptsReached = true;
                }
            } else {
                this.IsLoginAttemptsReached = false;
            }
        }, this.checkCookieInterval * 1000);
    }

    resetLoginAttempt(email) {
        let submitData = {
            email: email
        };
        let logInSubscription = this.api.updateLoginAttempts(submitData).subscribe(resultData => {
            if (resultData.success) {
            } else {
            }
            logInSubscription.unsubscribe();
        });
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async unlockAccount(email) {
        await this.sleep(this.loginCookieExpiryTime * 60 * 1000);
        this.resetLoginAttempt(email)
        this.loginCounter = 0;
    }

    getTotalAttempts(email: string): Observable<string> {
        let attempt;
        var subject = new Subject<string>();
        this.api.fetchLoginAttempts(email).subscribe((resultData) => {
            if (resultData.success) {
                attempt = resultData.extras;
                subject.next(attempt);
            }
        });
        return subject.asObservable();
    }

    sendSuspiciousActivityEmail(): void {
        this.sub.sink = this.api
                            .suspiciousActivityEmail(this.formData.loginEmail,'login')
                            .pipe(tap({next: _ =>console.log(''), error: _ => console.log('Suspicious activity email error!!!')}))
                            .subscribe()
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

}
