import { Component, OnInit, Input } from '@angular/core';
import { LogoutService } from '../../logout.service';
import { ApiService } from '../../api.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    @Input() page: string = '';
    activeClass: string = '';
    homeActive: boolean = false;
    settingsActive: boolean = false;
    refreshActive: boolean = false;
    sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    intervalLoop: any;

    constructor(
        private api: ApiService,
        private logoutService: LogoutService
    ) {
        this.intervalLoop = setInterval(() => {
            this.checkAccountStatus(); 
        }, this.logoutService.getCheckAccountStatusTimer());
    }

    ngOnInit(): void {
        this.isTokenValid()
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        }
        switch (this.page) {
            case 'menu':
                this.homeActive = true;
                break;
            case 'settings':
                this.settingsActive = true;
                break;
        }
    }

    ngOnDestroy() {
        clearInterval(this.intervalLoop);
    }

    checkAccountStatus() {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        }
    }

    isTokenValid() {
        const error_code_arr = [401, 403];
        this.api.fetchSettings().subscribe({
            next: (data) => {
                if (data.message == 'token invalid') {
                    this.logoutService.logout();
                }
            },
            error: (error) => {
                error_code_arr.includes(error.status) ? this.logoutService.logout() : console.log(error);
            }
        });
    }

    goBack(event) {
        event.preventDefault();
        window.history.back();
    }

    goForward(event) {
        event.preventDefault();
        window.history.forward();
    }

    changeColour() {
        this.processChangeColour();
    }

    async processChangeColour() {
        this.refreshActive = true;
        await this.sleep(200);
        this.refreshActive = false;
    }
}
