<!-- <div #map id="alerts-map-container" class="map-container" leaflet [leafletOptions]="options" (leafletMapReady)="onMapReady($event)">
    <div id="alerts-map-container-info-window">
        <div id="alerts-map-container-alert-info"></div>
    </div>
</div> -->

<div #map id="map-container">
    <div id="map-container-info-window">
        <p *ngIf="disaplyNoDataMessage" class="" id="message">{{showNoDataMessage}}</p>
        <button class="wide-white-input close-map-button" id="hide-map" (click)="closeMap()">Close Map</button>
    </div>
</div>