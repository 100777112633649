import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MapService } from '../map.service';
import { LogoutService } from '../logout.service';
import { GeolocationService } from '@ng-web-apis/geolocation';
import { LoaderService } from 'src/app/loader.service';
import { take } from 'rxjs/operators';
import { ApiService } from '../api.service';

@Component({
    selector: 'app-hijack-hotspots',
    templateUrl: './hijack-hotspots.component.html',
    styleUrls: ['./hijack-hotspots.component.css']
})
export class HijackHotspotsComponent implements OnInit {

    @Output() showMapEvent = new EventEmitter<string>();
    @ViewChild('myOnOffSwitch') onOffSwitch: ElementRef;
    displayNoDataMessage: boolean = false;
    intervalLoop: any;

    mapShow: string;
    constructor(
        private loader: LoaderService,
        private api: ApiService,
        private map: MapService,
        private readonly geolocation$: GeolocationService,
        private logoutService: LogoutService
    ) {
        this.intervalLoop = setInterval(() => {
            this.checkAccountStatus();
        }, this.logoutService.getCheckAccountStatusTimer());
    }

    ngOnInit(): void {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        }
    }
            
    ngOnDestroy() {
        clearInterval(this.intervalLoop);
    }

    checkAccountStatus() {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        }
    }

    isTokenValid() {
        const error_code_arr = [401, 403];
        this.api.fetchSettings().subscribe({
            next: (data) => {
                if (data.message == 'token invalid') {
                    this.logoutService.logout();
                }
            },
            error: (error) => {
                error_code_arr.includes(error.status) ? this.logoutService.logout() : console.log(error);
            }
        });
    }

    showHijackHotspots() {
        this.loader.show();
        this.displayNoDataMessage = false;
        let lon: number, lat: number;
        let locationSubscription = this.geolocation$.pipe(take(1)).subscribe({
            next(position: any) {
                lat = position.coords.latitude;
                lon = position.coords.longitude;
            },
            complete: () => {
                // locationSubscription.unsubscribe();
                let hijackHotspotsSubscription = this.api.fetchHijackHotspots(lat, lon).subscribe((resultData) => {
                    this.loader.hide();
                    hijackHotspotsSubscription.unsubscribe();
                    if (resultData.success) {
                        try {
                            if (resultData.extras.hotspots.length < 1) {
                                this.displayNoDataMessage = true;
                            }
                        } catch (error) {

                        }
                        if (resultData.message !== 'token invalid') {
                            this.map.data = resultData;
                            this.updateMapShow('hijack-hotspots');
                        } else {
                            this.logoutService.logout();
                        }
                    }
                });
            }
        });
    }

    monitorHijackHotspots() {
        this.loader.show();
        let hijackHotspotsSubscription = this.api.fetchAllHijackHotspots().subscribe((resultData) => {
            this.loader.hide();
            hijackHotspotsSubscription.unsubscribe();
            if (resultData.success) {
                if (resultData.message !== 'token invalid') {
                    this.map.data = resultData;
                    this.updateMapShow('monitor-hijack-hotspots');
                } else {
                    this.logoutService.logout();
                }
            }
        });
    }

    checkboxChanged(state: boolean) {
        if (state) {
            this.monitorHijackHotspots();
        } else {

        }
    }

    updateMapShow(status: string) {
        if (!status) {
            this.onOffSwitch.nativeElement.checked = false;
        }
        this.mapShow = status;
    }

}
