import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class InstallButtonService {
    private promptEvent: any;
    status = false
    hideToolTip: boolean = false;
    constructor() { }

    public initPwaPrompt() {
        window.addEventListener('beforeinstallprompt', (event: any) => {
            event.preventDefault();
            this.promptEvent = event;
            this.setButton(true);
        });
    }

    public setButton(arr) {
        return this.status = arr;
    }

    public installPwa() {
        this.setButton(false);
        this.promptEvent.prompt();
        this.promptEvent.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
            } else {
            }
            this.promptEvent = null;
        });
    }

    public setTooltip(arr) {
        return this.hideToolTip = arr;
    }

    isPwaInstalled() {
        return window.matchMedia('(display-mode: standalone)').matches;
    }
}
