<div id="menu">
    <div class="page-header-block">
        <h1 class="page-header">Route Planner</h1>
    </div>

    <div class="ui-content">

        <div *ngIf="empty_inputs">
            <div class="alert alert-container">
                <span class="closeAlertBtn" (click)="hideToolTip()">&times;</span>
                Please make sure that both the Origin and Destination fields are filled
            </div>
        </div>

        <div id="feedback-form" [hidden]="isFormHidden">
            <div>
                <form [formGroup]="routeForm" (ngSubmit)="showRoute()">

                    <input class="origin" (click)="searchLocation($event)" type="text" formControlName="origin"
                        id="origin" value="{{ originValue }}" placeholder="origin" [required]="true" />

                    <p class="current-location" (click)="currentLocation()">use current location</p>

                    <input class="destination" (click)="searchLocation($event)" type="text"
                        formControlName="destination" id="destination" value="{{ destinationValue }}"
                        placeholder="Destination" />

                    <select formControlName="route_profile" class="form-select routing-profile"
                        aria-label="Default select example">
                        <option value="" selected disabled>Mode of transportation</option>
                        <option value="driving-car">Driving</option>
                        <option value="foot-walking">Walking</option>
                        <option value="cycling-regular">Cycling</option>
                    </select>

                    <button type="submit" class="get-route-btn">
                        Get route
                    </button>

                </form>
            </div>
        </div>

        <div class="map-container" [hidden]="isMapHidden">
            <div class="map-frame">
                <div id="geocoder" [hidden]="hideGeocoder" class="geocoder"></div>
                <div id="map" [hidden]="hideMapBox"></div>

                <!-- <button [hidden]="closeMapBtn" id="stepsBtn">steps</button> -->

                <button [hidden]="closeMapBtn" (click)="showSteps()"  type="button" class="btn btn-light" id="stepsBtn"><img src="../../../assets/images/steps.png" width="30px" alt=""></button>

                <div [hidden]="hideSteps">
                    <div class="card bg-light mb-3 stepsCard" [hidden]="closeMapBtn" style="max-width: 18rem;">
                        <div class="card-header" (click)="hideStepCard()">Directions</div>
                        <div *ngIf="steps" class="card-body overflow-auto">
                            <ul class="steps-list" *ngFor="let step of steps">
                                <li>{{step.instruction}}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div [hidden]="closeMapBtn" >
                    <div *ngIf="orsStatus == 404" class="orsError">
                        Route not found.
                    </div>    
                </div>

                <div class="text-center">
                    <button (click)="closeMap()" [hidden]="selectLocationBtn" id="closeMapButton">Select
                        location
                    </button>
                </div>

                <div class="text-center">
                    <button (click)="closeMap()" [hidden]="closeMapBtn" id="closeMapButton">Close map</button>
                </div>

            </div>
        </div>

    </div>

    <app-footer></app-footer>
</div>