import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { ReportComponent } from './report/report.component';
import { AlertsComponent } from './alerts/alerts.component';
import { MapsComponent } from './maps/maps.component';
import { HijackHotspotsComponent } from './hijack-hotspots/hijack-hotspots.component';
import { SettingsComponent } from './settings/settings.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RoutePlannerComponent } from './components/route-planner/route-planner.component';
import { NewsfeedComponent } from './components/newsfeed/newsfeed.component';
import { VideoRepositoryComponent } from './components/video-repository/video-repository.component';
import { FaqTabComponent } from './components/faq-tab/faq-tab.component';
import { ExternalMapComponent } from './external-map/external-map.component';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: '', component: MenuComponent },
    { path: 'report', component: ReportComponent },
    { path: 'alerts', component: AlertsComponent },
    { path: 'maps', component: MapsComponent },
    { path: 'hijack-hotspots', component: HijackHotspotsComponent },
    { path: 'settings', component: SettingsComponent },
    { path: 'route-planner', component: RoutePlannerComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'newsfeed', component: NewsfeedComponent },
    { path: 'video-repository', component: VideoRepositoryComponent },
    { path: 'faq-tab', component: FaqTabComponent },
    { path: 'external-map/:type', component: ExternalMapComponent }
];

@NgModule({
    // imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule {}
