// const url = 'http://api-absa.test/';
const url = 'https://api-absa.topicworx.co.za/';


export const videos = [
    {
      title: 'Bogus Police',
      url: url+"public/videos/bogus-police/playlist.m3u8",
      thumbnail: "bogus-police.png"
    },
    {
      title: 'Home Driveways',
      url: url+"public/videos/home-driveways-automated-gates/playlist.m3u8",
      thumbnail: "home-driveways.png"
    },
    {
      title: 'Relay Attacks',
      url: url+"public/videos/parking-areas/playlist.m3u8",
      thumbnail: "parking-areas.png"
    },
    {
      title: 'Road Spikes',
      url: url+"public/videos/road-spikes/playlist.m3u8",
      thumbnail: "roadspikes.png"
    },
    {
      title: 'Stage an accident & Tap-tap technique',
      url: url+"public/videos/tap-tap-staging-an-accident/playlist.m3u8",
      thumbnail: "tap-tap.png"
    },
    {
      title: 'The Attack, Alone in the vehicle',
      url: url+"public/videos/the-attack-alone-children/playlist.m3u8",
      thumbnail: "theattack.png"
    },
    {
      title: 'Traffic lights & Stop signs',
      url: url+"public/videos/traffic-lights/playlist.m3u8",
      thumbnail: "trafficlights.png"
    },
  ]