import { Injectable } from '@angular/core';

import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class LogoutService {
    checkAccountStatusTimer : number = 43200000; // 12 hours
    countAttempted = 0;
    constructor(private router: Router) { }

    logout() {
        localStorage.clear();
        this.router.navigate(['/login']);
    }

    getCheckAccountStatusTimer() {
        return this.checkAccountStatusTimer;
    }
}
