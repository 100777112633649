// import { ThrowStmt } from '@angular/compiler';
import { Injectable } from '@angular/core';
// import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import {io, Socket } from 'socket.io-client';
@Injectable({
    providedIn: 'root'
})
export class SocketService {
    socket: Socket;

    constructor() {
    }

    connect(): void {
         this.socket = io('https://socket.topicworx.co.za:4001');
        this.socket.emit('subscribeToServer', 5000);
    }

    getData(): Observable<any[]> {
        this.connect();
        return new Observable(observer => {
            this.socket.on('data', (msg:any) => {
                observer.next(msg);
            });
        });
        // return this.socket.fromEvent<any[]>('reading');
    }

}
