import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideUserIdleConfig } from 'angular-user-idle'

import { ToastrModule } from 'ngx-toastr';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { ReportComponent } from './report/report.component';
import { AlertsComponent } from './alerts/alerts.component';
import { MapsComponent } from './maps/maps.component';
import { HijackHotspotsComponent } from './hijack-hotspots/hijack-hotspots.component';
import { SettingsComponent } from './settings/settings.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { TokenInterceptorService } from './interceptors/token-interceptor.service';
import { LoadingInterceptorService } from './interceptors/loading-interceptor.service';
import { AlertsDisplayComponent } from './components/alerts-display/alerts-display.component';
import { MapComponent } from './components/map/map.component';
import { ReportFormComponent } from './components/report-form/report-form.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import {CookieService} from 'ngx-cookie-service';
import { RoutePlannerComponent } from './components/route-planner/route-planner.component';
import { SlideShowComponent } from "./components/slide-show/slide-show.component";
import { NgxSpinnerModule } from 'ngx-spinner';
import { InstallButtonService } from "./install-button.service";
import { DeviceDetectorService } from 'ngx-device-detector';
import { CheckLocationComponent } from './check-location/check-location.component';
import { NewsfeedComponent } from './components/newsfeed/newsfeed.component';
import { NewsFeedFooterComponent } from './components/news-feed-footer/news-feed-footer.component';
import { VideoRepositoryComponent } from './components/video-repository/video-repository.component';
import { FaqTabComponent } from './components/faq-tab/faq-tab.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ExternalMapComponent } from './external-map/external-map.component';

const socketConfig: SocketIoConfig = {
    url: environment.socketio.url + ':' + environment.socketio.port,
    options: environment.socketio.options
};
const installBtnInitializer = (InstallButtonService: InstallButtonService) => () => InstallButtonService.initPwaPrompt();

@NgModule({
    declarations: [
        AppComponent,
        MenuComponent,
        ReportComponent,
        AlertsComponent,
        MapsComponent,
        HijackHotspotsComponent,
        SettingsComponent,
        FooterComponent,
        LoginComponent,
        RegisterComponent,
        AlertsDisplayComponent,
        MapComponent,
        ReportFormComponent,
        LoadingSpinnerComponent,
        WelcomeComponent,
        ForgotPasswordComponent,
        RoutePlannerComponent,
        SlideShowComponent,
        CheckLocationComponent,
        NewsfeedComponent,
        NewsFeedFooterComponent,
        VideoRepositoryComponent,
        FaqTabComponent,
        ExternalMapComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        PdfViewerModule,
        ToastrModule.forRoot(
            {
                positionClass: 'toast-top-center',
                preventDuplicates: true
            }
        ),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        SocketIoModule.forRoot(socketConfig),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptorService,
            multi: true
        },
        {provide: APP_INITIALIZER, useFactory: installBtnInitializer, deps: [InstallButtonService], multi: true},
        provideUserIdleConfig({ idle: 10, timeout: 3600, ping: 10 }),
        CookieService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
