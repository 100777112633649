import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GeolocationService } from '@ng-web-apis/geolocation';
import { take } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LogoutService } from 'src/app/logout.service';
import { ApiService } from 'src/app/api.service';
import { LoaderService } from 'src/app/loader.service';

@Component({
    selector: 'app-report-form',
    templateUrl: './report-form.component.html',
    styleUrls: ['./report-form.component.css']
})
export class ReportFormComponent implements OnInit {
    @Input() formShow: string;
    @Input() category: string;
    @Input() categoryTitle: string;
    @ViewChild('pictureContainer') pictureContainer: ElementRef;
    pictureContainerClass: string = '';
    formData: any = {};
    images: Array<any> = [];
    latitude: number;
    longitude: number;
    intervalLoop: any;

    constructor(
        private loader: LoaderService,
        private readonly geolocation: GeolocationService,
        private santizer: DomSanitizer,
        private api: ApiService,
        private toastr: ToastrService,
        private logoutService: LogoutService

    ) {
        this.intervalLoop = setInterval(() => {
            this.checkAccountStatus(); 
        }, this.logoutService.getCheckAccountStatusTimer());
    }

    ngOnInit(): void {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        }
    }
    
    ngOnDestroy() {
        clearInterval(this.intervalLoop);
    }

    checkAccountStatus() {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        }
    }

    isTokenValid() {
        const error_code_arr = [401, 403];
        this.api.fetchSettings().subscribe({
            next: (data) => {
                if (data.message == 'token invalid') {
                    this.logoutService.logout();
                }
            },
            error: (error) => {
                error_code_arr.includes(error.status) ? this.logoutService.logout() : console.log(error);
            }
        });
    }

    submitReport(form: NgForm): void {
        this.loader.show();
        let submitData = {};
        submitData['incidentCategory'] = (
            this.category === 'malicious-damage-to-property' ? 'malicious-damage' : (
                this.category === 'smash-and-grab' ? 'smash-grab' : this.category
            )
        );
        submitData['dateTime'] = form.value['datetime-datetime'];
        delete form.value['datetime-datetime'];
        submitData['generalLocation'] = form.value['txt-general-location'];
        delete form.value['txt-general-location'];
        submitData['longitude'] = this.longitude;
        submitData['latitude'] = this.latitude;
        submitData['data'] = form.value;
        submitData['images'] = this.getImages();
        let report = this.api.sendReport(submitData).subscribe(resultData => {
            this.loader.hide();
            if (resultData.success) {
                if (resultData.message !== 'token invalid') {
                    form.resetForm();
                    this.images = [];
                    this.toastr.success('Your alert has been successfully submitted.', '', { toastClass: 'absa-toast' });
                } else {
                    this.logoutService.logout();
                }
            } else {
                this.toastr.error('We were unable to submit your alert at this time.', '', { toastClass: 'absa-toast' });
            }
            report.unsubscribe();
        });
    }

    getImages(): string {
        let imagesArray = [];
        for (let element of this.pictureContainer.nativeElement.children) {
            imagesArray.push(this.getBase64Image(element.getElementsByTagName('img')[0]));
        }
        return JSON.stringify(imagesArray);
    }

    updateImages(event: any): void {
        if (event.srcElement.files.length > 0) {
            for (let file of event.srcElement.files) {
                const image = document.createElement('img');
                image.src = URL.createObjectURL(file);
                this.images.push(image);
            }
        }
        this.updateImageDisplay();
    }

    updateImageDisplay() {
        if (this.pictureContainer.nativeElement.childElementCount > 0) {
            this.pictureContainerClass = 'picture-container-multiple-images';
        } else {
            this.pictureContainerClass = '';
        }
    }

    getImageSource(image) {
        return this.santizer.bypassSecurityTrustUrl(image.src);
    }

    deletePicture(event, image) {
        event.preventDefault();
        for (let counter = 0; counter < this.images.length; counter++) {
            if (this.images[counter] === image) {
                this.images.splice(counter, 1);
                break;
            }
        }
        this.updateImageDisplay();
    }


    setLocation() {
        let lon: number, lat: number;
        this.geolocation.pipe(take(1)).subscribe({
            next(position: any) {
                lat = position.coords.latitude;
                lon = position.coords.longitude;
            },
            complete: () => {
                this.latitude = lat;
                this.longitude = lon;
            }
        });

    }

    /**
     * A function pulled from http://jsfiddle.net/user/jasdeepkhalsa/
     * Converts an image to base 64 encoded string
     * It seems that imgElem can not be a jquery object. It must be created via
     * 'getElementById'. No idea why.
     * @param {img element} imgElem
     * @returns {string}
     */
    getBase64Image(imgElem) {
        // imgElem must be on the same server otherwise a cross-origin error will be thrown "SECURITY_ERR: DOM Exception 18"
        let canvas = document.createElement("canvas");
        canvas.width = imgElem.clientWidth;
        canvas.height = imgElem.clientHeight;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(imgElem, 0, 0);
        let dataURL = canvas.toDataURL("image/jpg");
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }

    public ngOnChanges() {
        if (this.category !== '') {
            this.formData.category = this.category;
            this.setLocation();
            this[this.category] = true;
        }
    }

}
