<div id="home">
    <div class="loading-container">
        <div class="loading">
            <span class="loading-text">Loading...</span>
        </div>
    </div>
    <div class="page-header-block">
        <h1 class="page-header">Alerts</h1>
    </div>
    
    <app-check-location></app-check-location>

    <div class="alerts-list">
        <app-map [show]="mapShow" (hideMapEvent)="updateMapShow($event)"></app-map>
    </div>
    <div class="ui-content" id="home-content">
        <div id="home-header-sub">
            <h2 id="secondary-alerts-header" class="secondary-header"><span id="alerts-header-icon" class="icon-background"></span>Alerts</h2>
        </div>

        <div class="alert install-container" [hidden]="ToolTipHidden">
            <span class="closebtn" (click)="hideToolTip()">&times;</span>
            There are no alerts nearby, consider increasing your radius
        </div>

        <app-alerts-display [alertsData]="alertsData" (showMapEvent)="updateMapShow($event)"></app-alerts-display>
        <button id="view-location" class="wide-white-input alerts-buttons" (click)="showLocation()">View current location</button>
        <button id="check-alerts" (click)="checkAlerts()" class="wide-white-input alerts-buttons">Check for new alerts</button>
        <button id="show-all-alerts" (click)="viewAllAlerts()" class="wide-white-input alerts-buttons">View all alerts</button>
        <p class="small-margin-paragraph indent-paragraph copyright-container">
            &copy; Copyright TopicWorx
        </p>
    </div>
    <app-footer></app-footer>
</div>