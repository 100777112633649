<div id="login" class="d-flex justify-content-center flex-nowrap">
    <div id="ctn-notify"></div>
    <div id="ctn-notify-error"></div>
    <div class="ui-content">
        <div class="page-header-block">
            <h1 class="page-header">Sign in</h1>
        </div>
        <form id="loginForm" #loginForm="ngForm">
            <div class="large-input-container form-group">
                <label class="large-red-input-label" id="login-username" for="loginEmail"><!-- --></label>
                <!-- --><input [(ngModel)]="formData.loginEmail" class="large-white-input form-control" (click)="IsLoginAttemptsReached && onDisableInputClick()" type="email" placeholder="Email address" name="loginEmail" id="loginEmail" #loginEmail="ngModel" required autocomplete="username">
            </div>
            <div *ngIf="loginEmail.invalid && (loginEmail.dirty || loginEmail.touched)" class="alert alert-danger">
                <div *ngIf="loginEmail.errors.required">
                    Email is required.
                </div>
            </div>
            <div class="large-input-container form-group">
                <label class="large-red-input-label" id="login-password" for="loginPassword"><!-- --></label>

                <input [(ngModel)]="formData.loginPassword" class="large-white-input form-control" type="password"  placeholder="********" name="loginPassword" (click)="IsLoginAttemptsReached && onDisableInputClick()" id="loginPassword" #loginPassword="ngModel" required autocomplete="current-password">

            </div>
            <div *ngIf="loginPassword.invalid && (loginPassword.dirty || loginPassword.touched)" class="alert alert-danger">
                <div *ngIf="loginPassword.errors.required">
                    Password is required.
                </div>
            </div>
            <div>
                <!-- <button type="submit" id="login-button" class="solid-light-red-button" [disabled]="!loginForm.valid || IsLoginAttemptsReached"  (click)="login()">Login</button> -->
           
                <button type="submit" id="login-button" class="submit-button /*inactive-button*/ solid-light-red-button ui-btn ui-btn-b ui-corner-all mc-top-margin-1-5" [disabled]="!loginForm.valid || IsLoginAttemptsReached"  (click)="login()">Login</button>
            </div>
            <div class="extra-top-margin login-page-extra">
                <p class="small-margin-paragraph indent-paragraph">
                    Not registered yet? <a routerLink="/register" id="register-link">Register Here</a>
                </p>
                <p class="small-margin-paragraph indent-paragraph">
                    <a [routerLink]="['/', 'forgot-password']"
                        id="access-fail">Can't access your account?</a>
                    <!-- <a target="_blank" rel="noopener" href="mailto:admin@topicworx.co.za?subject=I can't access my Stay Safe account&body=Please include the following in your email: First name, Surname and The email address you registered with as that is the one we will be sending the password reset link to." id="access-fail">Can't access your account?
                    </a> -->
                </p>
            </div>
        </form>
        <p class="small-margin-paragraph indent-paragraph copyright-container bottom-copyright-container">
            &copy; Copyright TopicWorx
        </p>
    </div>
</div>