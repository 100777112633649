import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { videos } from "./video-list";
import { LogoutService } from 'src/app/logout.service';
import { ApiService } from 'src/app/api.service';

declare const videojs: any;

@Component({
  selector: 'app-video-repository',
  templateUrl: './video-repository.component.html',
  styleUrls: ['./video-repository.component.css']
})
export class VideoRepositoryComponent implements OnInit {

    videos: any = videos;
    private player: any;
    intervalLoop: any;

    constructor(
        public sanitizer: DomSanitizer,
        elementRef: ElementRef,
        private logoutService: LogoutService,
        private api: ApiService
    ) {
        this.player = false;
        this.intervalLoop = setInterval(() => {
            this.checkAccountStatus();
        }, this.logoutService.getCheckAccountStatusTimer());
    }

    ngOnInit() {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        } else {
            this.videos = this.sanitizeUrl(this.videos);
        }
    }
    
    ngOnDestroy() {
        clearInterval(this.intervalLoop);
    }

    checkAccountStatus() {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        }
    }

    isTokenValid() {
        const error_code_arr = [401, 403];
        this.api.fetchSettings().subscribe({
            next: (data) => {
                if (data.message == 'token invalid') {
                    this.logoutService.logout();
                }
            },
            error: (error) => {
                error_code_arr.includes(error.status) ? this.logoutService.logout() : console.log(error);
            }
        });
    }

    ngAfterViewInit() {
        for (let index = 0; index < this.videos.length; index++) {
            this.initVideo("hlsvideo" + index)
        }
    }

    initVideo(id: string) {
        this.player = videojs(document.getElementById(id));
        this.player.muted(true);
    }

    sanitizeUrl(arr) {
        let new_arr = [];
        arr.forEach(el => {
            el.url = this.sanitizer.bypassSecurityTrustResourceUrl(el.url);
            new_arr.push(el);
        });
        return new_arr;
    }
}
