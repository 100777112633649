<div id="hijack-hotspots" class="black-page-background">
    <div class="page-header-block">
        <h1 class="page-header">Videos</h1>
    </div>
    <app-check-location></app-check-location>
    <div class="ui-content">
        <div class="container center-content">
            <div class="">
                <div *ngFor="let video of videos, index as i" class="col-sm-4">
                    <div class="container video-container mb-5">
                        <video id="hlsvideo{{i}}" poster="../../../assets/video-thumbnails/{{video.thumbnail}}" class="video-js vjs-default-skin vjs-big-play-centered vjs-fill"
                            preload="metadata" controls id="hlsvideo">
                            <source [src]="video.url"
                            type="application/x-mpegURL">>
                        </video>
                        <p class="video-title mb-4">{{video.title}}</p>
                    </div>
                </div>
                <br>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>
