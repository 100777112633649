import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { Observable, concatMap, from, map } from 'rxjs';
import { ApiService } from './api.service';
import { detectIncognito } from 'detectincognitojs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  constructor(
    private swPush: SwPush,
    private api: ApiService) { }

  setUpPushNotification(): Observable<any> {
    return this.api
               .fetchVapidKey()
               .pipe(concatMap(notificationKey => this.mergeVapidKeyWithPushSubscription(notificationKey.extras.key)))
               .pipe(concatMap(pushSubscription => this.subscriptPushNotificationChannel(pushSubscription)));
  }

  hasNotification(): Observable<boolean> {
    return from(detectIncognito()).pipe(map(status => status.isPrivate ? false : true));
  }

  subscriptPushNotificationChannel(pushSubscription: PushSubscription): Observable<any> {
    return this.api
               .sendPushSubscription(pushSubscription);
  }

  unSubscriptPushNotificationChannel(): Observable<boolean> {
    return from(navigator.serviceWorker.getRegistration())
      .pipe(concatMap(serviceWorkerRegistration => from(serviceWorkerRegistration.pushManager.getSubscription())))
      .pipe(concatMap(pushMangerSubscription => from(pushMangerSubscription.unsubscribe())))
      .pipe(map(_ => true));
  }

  isSubscribedPushNotification(): Observable<boolean> {
    
    return from(navigator.serviceWorker.getRegistration())
      .pipe(concatMap(serviceWorkerRegistration => from(serviceWorkerRegistration.pushManager.getSubscription())))
      .pipe(map(pushMangerSubscription => pushMangerSubscription ? true : false));
      
  }

  protected mergeVapidKeyWithPushSubscription(publicVapidKey): Observable<PushSubscription> {
    return from(this.swPush.requestSubscription({ serverPublicKey: environment.serverPublicKey }));
  }

}
