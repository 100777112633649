import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';
import { LogoutService } from '../logout.service';
import { MapService } from '../map.service';
import { ApiService } from '../api.service';
import { LoaderService } from 'src/app/loader.service';
@Component({
    selector: 'app-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {
    alertsData: object;
    allAlertsData: object;
    mapShow: string;
    sub = new SubSink();
    ToolTipHidden: boolean = true;
    intervalLoop: any;

    constructor(
        private loader: LoaderService,
        private router: Router,
        private api: ApiService,
        private map: MapService,
        private logoutService: LogoutService
    ) {
        this.intervalLoop = setInterval(() => {
            this.checkAccountStatus(); 
        }, this.logoutService.getCheckAccountStatusTimer());
    }

    ngOnInit() {
        this.isTokenValid();
        this.mapShow = 'false';
        if (localStorage.getItem('id') === null && localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        } else {
            this.api.sendLocation();
            this.checkAlerts();
        }
    }

    ngOnDestroy() {
        clearInterval(this.intervalLoop);
    }

    checkAccountStatus() {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        }
    }

    isTokenValid() {
        const error_code_arr = [401, 403];
        this.api.fetchSettings().subscribe({
            next: (data) => {
                if (data.message == 'token invalid') {
                    this.logoutService.logout();
                }
            },
            error: (error) => {
                error_code_arr.includes(error.status) ? this.logoutService.logout() : console.log(error);
            }
        });
    }

    checkAlerts() {
        this.loader.show()
        this.api.getAlerts().subscribe({
            next: (resultData) => {
                if (resultData.success) {
                    if (resultData.extras.articles.length > 0) {
                        if (resultData.message !== 'token invalid') {
                            this.loader.hide()
                            this.alertsData = resultData.extras.articles;
                        } else {
                            this.logoutService.logout();
                        }
                    }else{
                        this.loader.hide();
                        this.ToolTipHidden = false;
                    }
                }
            },
            error: (error) => {
                this.ToolTipHidden = false;
                this.loader.hide();
            }
        });
    }

    getAllAlerts() {
        this.loader.show()
        this.api.getAllAlerts().subscribe(resultData => {
            this.loader.hide();
            if (resultData.success) {
                if (resultData.message !== 'token invalid') {
                    this.map.data = resultData;
                    this.updateMapShow('all-alerts');
                    this.map.setShowAlerts(false);
                } else {
                    this.logoutService.logout();
                }
            }
        });
    }

    hideToolTip(){
        this.ToolTipHidden = true;
    }

    updateMapShow(status: string) {
        this.mapShow = status;
    }

    showLocation() {
        this.updateMapShow('my-location');
        this.map.setShowAlerts(false);

    }

    viewAllAlerts() {
        this.getAllAlerts();

    }

}
