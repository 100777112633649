import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { ApiService } from '../api.service';
import { tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LogoutService } from '../logout.service';
import { LoaderService } from 'src/app/loader.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  form: FormGroup;
  sent: boolean = false;
  private sub = new SubSink()

  constructor(
    private loader: LoaderService,
    private logoutService: LogoutService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private api: ApiService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
        'email': ['', [Validators.required, Validators.email]]
      });
  }

  sendResetPasswordLink(): void {
    this.loader.show()
    this.sub.sink = this.api
                        .forgotPassword(this.form.value.email)
                        .pipe(tap({next: _ => this.successResponseHandler(_), error: _ => this.errorResponseHandler(_)}))
                        .subscribe()
  }

  private successResponseHandler(response: any): void {
    this.loader.hide();
    this.sent = true;
    this.form.reset();
    // this.toastr.success(
    //   '',
    //   response?.error?.message ? response?.error?.message : response.message,
    //   { toastClass: 'absa-toast' }
    // );
  }

  private errorResponseHandler(response: any): void {
    this.loader.hide();
    this.toastr.error(
      response?.error?.errors ? Object.values(response?.error?.errors).join(' and ') : '',
      response?.error?.message ? response?.error?.message : response.message,
      { toastClass: 'absa-toast' }
    );
  }

  ngOnDestroy(): void {
      this.sub.unsubscribe();
  }
}
