<div [hidden]="hideContainer" [@closeContainer]="containerOpen ? 'open' : 'closed'"
    [ngStyle]="{'z-index': hideContainer ? 0 : 1}" id="welcome" *ngIf="show">

    <img [hidden]="hide1" [@showHide]="message1Open  ? 'visible' : 'hidden'" class="logo" src="../../../assets/images/Absa - branch_icon.svg">
    <picture [hidden]="hide1" [@showHide]="message1Open  ? 'visible' : 'hidden'" class="image">
        <source class="largImage" media="(min-width:650px)" srcset="../../../assets/slideShow/image1.png">
        <img  src="../../../assets/slideShow/mobile1.png">
    </picture>

    <img [hidden]="hide2" [@showHide]="message2Open  ? 'visible' : 'hidden'" class="logo" src="../../../assets/images/Absa - branch_icon.svg">
    <picture [hidden]="hide2" [@showHide]="message2Open  ? 'visible' : 'hidden'" class="image">
        <source class="largImage" media="(min-width:650px)" srcset="../../../assets/slideShow/image2.png">
        <img  src="../../../assets/slideShow/mobile2.png">
    </picture>

</div>