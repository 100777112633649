import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiService } from '../api.service';
import { LogoutService } from '../logout.service';

@Component({
    selector: 'app-external-map',
    templateUrl: './external-map.component.html',
    styleUrls: ['./external-map.component.css']
})

export class ExternalMapComponent {

    intervalLoop: any;

    /**
     * Constuctor
     * 
     * @param route 
     * @param domSanitizer 
     * 
     * @returns void
     */
    constructor(
        private route: ActivatedRoute,
        public domSanitizer: DomSanitizer,
        private logoutService: LogoutService,
        private api: ApiService
    ) {
        this.intervalLoop = setInterval(() => {
            this.checkAccountStatus();
        }, this.logoutService.getCheckAccountStatusTimer());
    }

    /**
     * Variables
     */
    displayExternalMap: boolean = false;
    externalMapUrl: any;

    /**
     * Execute on page load
     * 
     * @returns void
     */
    ngOnInit() {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        } else {
            this.route.paramMap.subscribe((params) => {
                let mapType: string = params.get('type')!;
                this.setupMap(this.getLink(mapType));
            });
        }
    }
        
    ngOnDestroy() {
        clearInterval(this.intervalLoop);
    }

    checkAccountStatus() {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        }
    }

    isTokenValid() {
        const error_code_arr = [401, 403];
        this.api.fetchSettings().subscribe({
            next: (data) => {
                if (data.message == 'token invalid') {
                    this.logoutService.logout();
                }
            },
            error: (error) => {
                error_code_arr.includes(error.status) ? this.logoutService.logout() : console.log(error);
            }
        });
    }

    /**
     * Get the URL based on the type arg supplied
     * 
     * @param type string The type of URL required
     * 
     * @returns string
     */
    getLink(type: string) {
        switch (type) {
            case 'spikingHotspot':
                return 'https://resource.topicworx.co.za/map/render/a0a5e2bd73554507839a400c7608d237';
                break;
            case 'smallSpikingHotspot':
                return 'https://resource.topicworx.co.za/map/rendersmall/a0a5e2bd73554507839a400c7608d237';
                break;
            default:
                return '';
                break;
        }
    }

    /**
     * Sanitize the URL
     * 
     * @param link 
     * 
     * @returns void
     */
    setupMap(link: string) {
        if (link != '') {
            this.displayExternalMap = true;
            this.externalMapUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(link);
        }
    }
}