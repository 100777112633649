<div id="settings">
    <div class="page-header-block">
        <h1 class="page-header">Settings</h1>

    </div>
    <app-check-location></app-check-location>
    <div id="ctn-notify"></div>
    <div id="ctn-notify-error"></div>
    <div role="main" class="ui-content" id="settings-content">
        <div id="settings-header-sub">
            <!-- <h2 id="secondary-settings-header" class="secondary-header"><span id="settings-icon"
                    class="icon-background ui-icon-settings unselectable"></span>Settings</h2> -->
        </div>
        <form (ngSubmit)="submitSettings()">
            <!-- <form> -->
            <label class="form-label" for="radius">Radius(in km): </label>
            <!-- <input ngModel type="number" max="250" name="radius" id="radius" class="wide-white-input" [value]="radius"> -->

            <input type="number" max="250" name="radius" id="radius" class="wide-white-input"
                [formControl]="radiusFormControl">
            <div class="wide-white-input skinny-input fake-input">

                <label for="check-cellularData" class="checkbox-label">
                    <span id="pseudo-selector-check-cellularData" class="pseudo-checkbox"
                        [class.pseudo-checkbox-selected]="cellularData"></span>
                    <span class="pseudo-checkbox-label-text unselectable"> Allow Cellular Data?</span>
                </label>
                <!-- <input ngModel type="checkbox" name="check-cellularData" class="bi-invisible" id="check-cellularData" [checked]="cellularData" (change)="checkboxToggle($event)"> -->
                <input type="checkbox" name="check-cellularData" class="bi-invisible" id="check-cellularData"
                    [formControl]="cellularDataFormControl" (change)="checkboxToggle($event)">
            </div>
            <div class="wide-white-input skinny-input fake-input">

                <label for="check-notificationVibrate" class="checkbox-label">
                    <span id="pseudo-selector-check-notificationVibrate" class="pseudo-checkbox"
                        [class.pseudo-checkbox-selected]="notificationVibrate"></span>
                    <span class="pseudo-checkbox-label-text unselectable"> Vibrate on incoming alert?</span>
                </label>
                <!-- <input ngModel type="checkbox" name="check-notificationVibrate" class="bi-invisible" id="check-notificationVibrate" [checked]="notificationVibrate" (change)="checkboxToggle($event)"> -->
                <input type="checkbox" name="check-notificationVibrate" class="bi-invisible"
                    id="check-notificationVibrate" [formControl]="notificationVibrateFormControl"
                    (change)="checkboxToggle($event)">
            </div>
            <div class="wide-white-input skinny-input fake-input">

                <label for="check-notificationSound" class="checkbox-label">
                    <span id="pseudo-selector-check-data-notificationSound" class="pseudo-checkbox"
                        [class.pseudo-checkbox-selected]="notificationSound"></span>
                    <span class="pseudo-checkbox-label-text unselectable"> Sound on incoming alert?</span>
                </label>
                <!-- <input ngModel type="checkbox" name="check-notificationSound" class="bi-invisible" id="check-notificationSound" [checked]="notificationSound" (change)="checkboxToggle($event)"> -->
                <input type="checkbox" name="check-notificationSound" class="bi-invisible" id="check-notificationSound"
                    [formControl]="notificationSoundFormControl" (change)="checkboxToggle($event)">
            </div>

            <div class="wide-white-input skinny-input fake-input" (click)="unsubscribeToNotifications()"
                *ngIf="subscribedToNotifications">

                <label for="check-isNotificationOn" class="checkbox-label">
                    <span id="pseudo-selector-check-data-isNotificationOn " class="pseudo-checkbox"
                        [class.pseudo-checkbox-selected]="true"></span>
                    <span class="pseudo-checkbox-label-text unselectable"> Recieve push notifications</span>
                </label>
            </div>

            <div class="wide-white-input skinny-input fake-input" (click)="subscribeToNotifications()"
                *ngIf="!subscribedToNotifications">
                <label for="check-isNotificationOn" class="checkbox-label">
                    <span id="pseudo-selector-check-data-isNotificationOn" class="pseudo-checkbox"
                        [class.pseudo-checkbox-selected]="false"></span>
                    <span class="pseudo-checkbox-label-text unselectable"> Recieve push notifications</span>
                </label>
                <!-- <input type="checkbox" name="check-isNotificationOn"  id="check-isNotificationOn" [formControl]="subscribedToNotifications" (change)="checkboxToggle($event)"> -->
            </div>

            <button type="submit" id="btn-update" class="submit-button">Update</button>
            
        </form>

        <p id="contact-issues">If you experience any issues please <a id="contact-link" class="no-bold-link"
                href="mailto:staysafe@absa.africa">contact us</a>.</p>
        <p class="small-margin-paragraph indent-paragraph copyright-container">
            &copy; Copyright TopicWorx
        </p>
    </div>
    
    <!-- /content -->
    <app-footer [page]=pageName></app-footer>
</div>