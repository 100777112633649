export const environment = {
    production: true,
    mapbox: {
        accessToken: 'pk.eyJ1Ijoic2ltb250b3BpY3dvcngiLCJhIjoiY2s2N3F2a3J6MWJjeDNnbzdqOWZrbGE0ZyJ9.AtrNSmqY4XI09AbeiFI6DA'
    },
    socketio: {
        url: 'socket.topicworx.co.za',
        port: 4001,
        options: {}
    },
    openrouteservice_key : "5b3ce3597851110001cf6248c746200ff60f4dc7846ae06645a39f04",
    serverPublicKey: "BH_dcIZ8glpzcPmrWPMaH_pGVDtf81y12YU7ivB1RMn1LcuNS7EZL-hze5_G4lC-2BJE0-C1jBD-XXKTVBlTbS0",
};

export const menu = {
    visited: false
}
