<div data-role="toolbar" data-type="footer" class="footer">
    <ul class="footer-list">
        <!--
                    -->
        <li class="left first end">
            <a class="history-link" id="history-link-back" href="" (click)="goBack($event)">Back</a>
        </li>
        <!--
                    -->
        <li class="landing">
            <a routerLink="/" data-transition="slide" id="landing-link" [ngClass]="{'footer-active': homeActive}">Home</a>
        </li>
        <!--
                    -->
        <li class="refresh">
            <a routerLink="/alerts" id="refresh-link" (click)="changeColour()" [ngClass]="{'footer-active': refreshActive}">Refresh</a>
        </li>
        <!--
                    -->
        <li class="settings">
            <a routerLink="/settings" data-transition="slide" id="settings-link" [ngClass]="{'footer-active': settingsActive}">Settings</a>
        </li>
        <!--
                    -->
        <li class="right last end">
            <a class="history-link" id="history-link-forward" href="" (click)="goForward($event)">Forward</a>
        </li>
        <!--
                    -->
    </ul>
</div>