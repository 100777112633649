<div *ngIf="showRegister" id="register" class="d-flex justify-content-center flex-nowrap">
    <!-- /header -->
    <div id="ctn-notify"></div>
    <div id="ctn-notify-error"></div>
    <div role="main" class="ui-content">
        <div class="page-header-block">
            <h1 class="page-header">Register</h1>
        </div>
        <form id="registrationForm" #registrationForm="ngForm">
            <div class="form-group">
                <label class="form-label" for="registrationFirstName">First name</label>
                <input class="wide-white-input" type="text" (click)="isRegisterAttemptsReached && onDisableInputClick()" [(ngModel)]="formData.registrationFirstName" name="registrationFirstName" #registrationFirstName="ngModel" id="registrationFirstName" required>
            </div>
            <div *ngIf="registrationFirstName.invalid && (registrationFirstName.dirty || registrationFirstName.touched)" class="alert alert-danger">
                <div *ngIf="registrationFirstName.errors.required">
                    <span>First name is required.</span>
                </div>
            </div>
            <div class="form-group">
                <label class="form-label" for="registrationLastName">Last name</label>
                <input class="wide-white-input" type="text" (click)="isRegisterAttemptsReached && onDisableInputClick()" [(ngModel)]="formData.registrationLastName" name="registrationLastName" #registrationLastName="ngModel" id="registrationLastName" required>
            </div>
            <div *ngIf="registrationLastName.invalid && (registrationLastName.dirty || registrationLastName.touched)" class="alert alert-danger">
                <div *ngIf="registrationLastName.errors.required">
                    <span>Last name is required.</span>
                </div>
            </div>
            <div class="form-group">
                <label class="form-label" for="registrationTel">Contact number</label>
                <input class="wide-white-input" type="tel" (click)="isRegisterAttemptsReached && onDisableInputClick()" [(ngModel)]="formData.registrationTel" name="registrationTel" #registrationTel="ngModel" id="registrationTel" required>
            </div>
            <div *ngIf="registrationTel.invalid && (registrationTel.dirty || registrationTel.touched)" class="alert alert-danger">
                <div *ngIf="registrationTel.errors.required">
                    <span>Contact number is required.</span>
                </div>
            </div>
            <div class="form-group">
                <label class="form-label" for="registrationEmail">Email address</label>
                <input class="wide-white-input" type="text" (click)="isRegisterAttemptsReached && onDisableInputClick()" [(ngModel)]="formData.registrationEmail" name="registrationEmail" #registrationEmail="ngModel" id="registrationEmail" required>
            </div>
            <div *ngIf="registrationEmail.invalid && (registrationEmail.dirty || registrationEmail.touched)" class="alert alert-danger">
                <div *ngIf="registrationEmail.errors.required">
                    <span>Email address is required.</span>
                </div>
            </div>
            <div class="form-group">
                <label class="form-label" for="registrationPassword">Password</label>
                <input class="wide-white-input" type="password" (click)="isRegisterAttemptsReached && onDisableInputClick()" [(ngModel)]="formData.registrationPassword" name="registrationPassword" #registrationPassword="ngModel" id="registrationPassword" required>
            </div>

            <div *ngIf="registrationPassword.invalid && (registrationPassword.dirty || registrationPassword.touched)" class="alert alert-danger">
                <div *ngIf="registrationPassword.errors.required">
                    <span>Password is required.</span>
                </div>
            </div>
            
            <div class="form-group">
                <label class="form-label" for="registrationPasswordConfirm">Confirm password</label>
                <input class="wide-white-input" type="password"  (click)="isRegisterAttemptsReached && onDisableInputClick()" [(ngModel)]="formData.registrationPasswordConfirm" name="registrationPasswordConfirm" #registrationPasswordConfirm="ngModel" id="registrationPasswordConfirm" required>
            </div>
            
            <div *ngIf="registrationPasswordConfirm.invalid && (registrationPasswordConfirm.dirty || registrationPasswordConfirm.touched)" class="alert alert-danger">
                <div *ngIf="registrationPasswordConfirm.errors.required">
                    <span class="">Confirm password is required.</span>
                </div>
            </div>

            <div id="registration-disclaimer">
                <span id="checkbox-registration-disclaimer-fake" class="fake-checkbox pseudo-square-checkbox">
                    <span class="fake-checkbox-tick" id="checkbox-regsitration-disclaimer-fake-tick"></span>
                </span>
                <div class="form-group">
                    <input type="checkbox" class="/*bi-invisible*/ real-checkbox" [(ngModel)]="formData.registrationDisclaimerCheckbox" name="registrationDisclaimerCheckbox" #registrationDisclaimerCheckbox="ngModel" id="registrationDisclaimerCheckbox" required>
                    <label for="registrationDisclaimerCheckbox" class="square-checkbox-label">
                    By ticking this box on the Stay Safe App, you agree to our
                    <a (click)="showExtras($event, 'showDisclaimer')" href="#">Disclaimer</a>, 
                    <a (click)="showExtras($event, 'showPrivacyNotice')" href="#">Privacy Notice</a> and 
                    <a (click)="showExtras($event, 'showUsagePolicy')" href="#">Usage Policy</a> and confirm that you have read the 
                    <a href="https://topicworx.co.za/eula/">Terms and Conditions</a>.
                </label>
                </div>
            </div>
            
            <button type="submit" id="reg-submit-button" class="submit-button /*inactive-button*/ solid-light-red-button ui-btn ui-btn-b ui-corner-all mc-top-margin-1-5" [disabled]="!registrationForm.form.valid || isRegisterAttemptsReached" (click)="register()">Submit</button>
        </form>

        <div class="popup-screen" *ngIf="showDisclaimer">
            <div id="disclaimer-popup">
                <a href="#" (click)="closeExtras($event, 'showDisclaimer')" class="ui-btn-right">Close</a>
                <h4>Disclaimer</h4>
                <p>This app is provided by Absa Group Ltd and its affiliates (“Absa”) for your personal safety and security management. It's developed by a third-party, TopicWorx, and is made available under a general license as a service for Absa staff and contractors.</p>
                <p>You may get safety and security updates based on your location and can share information with other app users. By using this app, you consent to your data being shared with TopicWorx to enable these features.</p>
                <p>Information shared on this app is for your personal use only and should not be redistributed to external parties, social media, or community groups. Avoid using this app while driving. Absa is not liable for any issues arising from your use or inability to use this app.</p>
                <p>Please read and understand TopicWorx’s <a href="https://topicworx.co.za/eula/">terms and conditions</a>.</p>
            </div>
        </div>
        <div class="popup-screen" *ngIf="showPrivacyNotice">
            <div id="privacy-popup">
                <a href="#" (click)="closeExtras($event, 'showPrivacyNotice')" class="ui-btn-right">Close</a>
                <h4>Privacy Policy</h4>
                <p>As part of our commitment to you as a valued employee, we would like to let you know that we continue to process your personal information for the purposes of your employment (including employee monitoring). This processing may include
                    the sharing of your personal information across Absa Group Limited ("Absa") and with third parties, including regulators, when required. Your use of this application is limited only to the collection of your name, surname, work email
                    address and mobile number for registration and authentication purposes. You have rights in terms of applicable data privacy legislation and, if you would like to exercise those rights, or raise a concern regarding the processing of
                    this information, please contact your local HR Business Partner. To read our Privacy Statement, please visit absa.co.za or the absa.africa website.</p>
            </div>
        </div>
        <div class="popup-screen" *ngIf="showUsagePolicy">
            <div id="usage-popup">
                <a href="#" (click)="closeExtras($event, 'showUsagePolicy')" class="ui-btn-right">Close</a>
                <h4>Usage Policy</h4>
                <p>Please ensure that your usage of this application complies with the Group Data Privacy Policy and other applicable Absa Group policies and associated standards, which is available on the Policies and Procedures Site.</p>
            </div>
        </div>
        <p class="small-margin-paragraph indent-paragraph copyright-container">
            &copy; Copyright TopicWorx
        </p>
    </div>
    <!-- /content -->
</div>
<!-- register -->
<div *ngIf="showRegisterSuccess">
    <div role="main" class="content">
        <img class="absa-logo" src="../../assets/images/red_absa_logo.svg" alt="">
        <div class="alert alert-danger response-alert" role="alert">
            Congratulations! You are now registered with the Stay Safe app. You will be contacted shortly on the email you registered with to complete the activation process.
        </div>
        <a routerLink="/login" class="submit-button login-button" >Login</a>
        <p class="small-margin-paragraph indent-paragraph copyright-container">
            &copy; Copyright TopicWorx
        </p>
    </div>
</div>
<!-- /page -->