<div class="page-header-block">
    <h1 class="page-header">News Feed</h1>
</div>

<br>
<br>
<br>

<app-check-location></app-check-location>

<div class="newsfeed-container">
    <rssapp-list id="_QyELQ6bFbIFIHCcK"></rssapp-list>
</div>

<app-news-feed-footer></app-news-feed-footer>