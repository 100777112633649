import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DomEvent } from 'leaflet';
import { MapService } from '../map.service';
import { LogoutService } from '../logout.service';
import { GeolocationService } from '@ng-web-apis/geolocation';
import { LoaderService } from 'src/app/loader.service';
import { take } from 'rxjs/operators';
import { ApiService } from '../api.service';

@Component({
    selector: 'app-maps',
    templateUrl: './maps.component.html',
    styleUrls: ['./maps.component.css']
})
export class MapsComponent implements OnInit {
    @Output() showMapEvent = new EventEmitter<string>();
    mapShow: string;
    intervalLoop: any;

    constructor(
        private loader: LoaderService,
        private api: ApiService,
        private map: MapService,
        private logoutService: LogoutService,
        private readonly geolocation$: GeolocationService,
        private router: Router,
    ) {
        this.intervalLoop = setInterval(() => {
            this.checkAccountStatus();
        }, this.logoutService.getCheckAccountStatusTimer());
    }

    ngOnInit(): void {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        }
    }

    ngOnDestroy() {
        clearInterval(this.intervalLoop);
    }

    checkAccountStatus() {
        this.isTokenValid();
        if (localStorage.getItem('id') === null || localStorage.getItem('jwt') === null) {
            this.logoutService.logout();
        }
    }

    isTokenValid() {
        const error_code_arr = [401, 403];
        this.api.fetchSettings().subscribe({
            next: (data) => {
                if (data.message == 'token invalid') {
                    this.logoutService.logout();
                }
            },
            error: (error) => {
                error_code_arr.includes(error.status) ? this.logoutService.logout() : console.log(error);
            }
        });
    }

    showAllAlerts() {
        this.loader.show();
        let allAlertsSubscription = this.api.getAllAlerts().subscribe(resultData => {
            try {
                allAlertsSubscription.unsubscribe();
            } catch (error) {
                //Do something                    
            } finally {
                if (resultData.success) {
                    if (resultData.message !== 'token invalid') {
                        this.loader.hide();
                        this.map.data = resultData;
                        this.updateMapShow('all-alerts');
                    } else {
                        this.loader.hide();
                        this.logoutService.logout();
                    }
                }
            }
        });
    }

    routePlanner() {
        this.updateMapShow('places');
    }

    showPlaces(event: any) {
        this.loader.show();
        let type = event.target.id.replace('btn-view-', '');
        let lon: number, lat: number;
        let locationSubscription = this.geolocation$.pipe(take(1)).subscribe({
            next(position: any) {
                lat = position.coords.latitude;
                lon = position.coords.longitude;
            },
            complete: () => {
                try {
                    locationSubscription.unsubscribe();
                } catch (error) {
                    //Do something                    
                }
                if (type == "branches") {
                    this.showBranches();
                } else if (type == "atms") {
                    this.showAtms()
                } else if (type == "gas_station") {
                    this.get_gas_station(lat, lon, type);
                } else {
                    this.getPlaces(lat, lon, type);
                }
            }
        });
    }

    get_gas_station(lat, lon, type) {
        let placesSubscription = this.api.fetchPlaces(lat, lon, type).subscribe((resultData) => {
            try {
                placesSubscription.unsubscribe();
            } catch (error) {
                //Do something                    
            } finally {
                if (resultData.message !== 'token invalid') {
                    this.loader.hide();
                    let locations = this.filterLocations(resultData.extras.places)
                    this.map.data = locations;
                    this.updateMapShow('places');
                } else {
                    this.loader.hide();
                    this.logoutService.logout();
                }
            }
        });
    }

    filterLocations(locations) {
        let new_data = []
        locations.forEach((location) => {
            if (location.name.toLowerCase().includes('sasol')) {
                new_data.push(location);
            };
        });
        return new_data
    }

    getPlaces(lat, lon, type) {
        let placesSubscription = this.api.fetchPlaces(lat, lon, type).subscribe((resultData) => {
            try {
                placesSubscription.unsubscribe();
            } catch (error) {
                //Do something                    
            } finally {
                if (resultData.message !== 'token invalid') {
                    this.loader.hide();
                    this.map.data = resultData.extras.places;
                    this.updateMapShow('places');
                } else {
                    this.loader.hide();
                    this.logoutService.logout();
                }
            }
        });
    }

    showAtms() {
        let placesSubscription = this.api.fetchAtms().subscribe((resultData) => {
            try {
                placesSubscription.unsubscribe();
            } catch (error) {
                //Do something                    
            } finally {
                if (resultData.message !== 'token invalid') {
                    this.loader.hide();
                    this.map.data = resultData.extras.atms;
                    this.updateMapShow('atms');
                } else {
                    this.loader.hide();
                    this.logoutService.logout();
                }
            }
        });

    }

    showBranches() {
        ;
        let placesSubscription = this.api.fetchBranches().subscribe((resultData) => {
            try {
                placesSubscription.unsubscribe();
            } catch (error) {
                //Do something                    
            } finally {
                if (resultData.message !== 'token invalid') {
                    this.loader.hide();
                    this.map.data = resultData.extras.branches;
                    this.updateMapShow('branches');
                } else {
                    this.loader.hide();
                    this.logoutService.logout();
                }
            }
        });
    }

    updateMapShow(status: string) {
        this.mapShow = status;
    }

    /**
     * Router navigation to external map component with URL arguments
     * 
     * @param name string URL arg
     * 
     * @returns void
     */
    navigateExternal(name: string) {
        this.router.navigate([`/external-map/${name}`]);
    }
}
